import React from 'react';

import { useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';
import { useSettings } from '@wix/tpa-settings/react';
import { ChallengeDetails } from '../../../../../../../../components-shared/ChallengeDetails';
import challengeSettings from '../../../../../../settingsParams';
import { TextAlignment } from '../../../../../../Settings/challengeSettings/challengeSettings.types';

import { st, classes } from './VisitorPageProgramDetails.st.css';
import {
  useCSSPBEnabled,
  useCSSPBStyle,
} from '../../../../../../../../hooks/useCSSPBStyle';
import { FCWithChildren } from '@wix/challenges-web-library';
import { useChallengeData } from '../../../../../../../../contexts/storage-contexts/Challenge';
import { useGeneralData } from '../../../../../../../../contexts/GeneralDataProvider/GeneralDataContext';
import { useLocation } from '../../../../../../../../contexts/Location/LocationContext';

interface IVisitorPageProgramDetailsProps {
  className?: string;
  alignment?: TextAlignment;
}

export const VisitorPageProgramDetails: FCWithChildren<
  IVisitorPageProgramDetailsProps
> = ({ className = null, alignment = TextAlignment.Left }) => {
  const { t } = useTranslation();
  const { isMobile, isRTL } = useEnvironment();
  const {
    challengeData: { challenge },
  } = useChallengeData();
  const settings = useSettings();
  const { msid } = useGeneralData();
  const { baseUrl } = useLocation();
  const inviteLink = `${baseUrl}/_serverless/challenges-web-serverless/redirect-invite-link?programId=${challenge.id}&msid=${msid}`;
  const cssPBEnabled = useCSSPBEnabled();
  const showOneApp =
    cssPBEnabled || settings.get(challengeSettings.displayOneApp);
  const getCSSPBStyle = useCSSPBStyle();

  return (
    <div
      className={st(
        classes.root,
        {
          mobile: isMobile,
          alignment,
        },
        className,
      )}
      dir={isRTL ? 'rtl' : 'auto'}
    >
      {challenge.settings.description.details ? (
        <div className={classes.contentDescription}>
          <ChallengeDetails
            isMobile={isMobile}
            details={challenge.settings.description.details}
          />
        </div>
      ) : null}

      {showOneApp ? (
        <p
          className={classes.contentDescription}
          style={getCSSPBStyle({ displayVar: '--opgDisplayOneApp' })}
        >
          {t('challenge.description.requires-one-app')}{' '}
          <a
            href={inviteLink}
            target="_blank"
            className={classes.link}
            rel="noreferrer"
          >
            {t('challenge.description.mobile-app')}
          </a>
        </p>
      ) : null}
    </div>
  );
};
