import { useControllerData } from '../../main/controllerContext';
import { IPaidPlansContext } from './interfaces';

export const useMemberPaidPlans = (): IPaidPlansContext => {
  const props = useControllerData();

  return {
    eligiblePlans: props.eligiblePlans,
    userPaidPlans: props.userPaidPlans,
  };
};
