import {
  MediaItem,
  Image,
} from '@wix/ambassador-challenges-v1-challenge/types';
import {
  imageDataToMediaUrl,
  fixVideoThumbnailFromMobile,
} from '@wix/challenges-web-library';

export const getMediaPreview = (
  media: MediaItem,
  width = 300,
  height = 200,
  quality = 100,
  format = 'wepb',
) => {
  if (media?.video?.thumbnail?.url) {
    media.video.thumbnail.url = fixVideoThumbnailFromMobile(
      media.video.thumbnail.url,
    );
  }

  return media && (media.image || media.video)
    ? imageDataToMediaUrl({
        ...(media.video ? media.video.thumbnail : (media.image as any)),
        width,
        height,
        quality,
        format,
      })
    : null;
};

export const getMediaImagePreview = (
  thumbnail: Image,
  width = 300,
  height = 200,
  quality = 100,
) => {
  if (thumbnail?.url) {
    thumbnail.url = fixVideoThumbnailFromMobile(thumbnail.url);
  }

  return thumbnail
    ? imageDataToMediaUrl({
        ...(thumbnail as any),
        width,
        height,
        quality,
      })
    : null;
};
