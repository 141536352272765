import React from 'react';

import { useTranslation, useBi } from '@wix/yoshi-flow-editor';

import GroupIcon from '../../assets/icons/groups-new.svg';

import { st, classes } from './SocialGroupLink.st.css';

import { useSocialGroups } from '../../contexts/SocialGroups/SocialGroupsContext';
import { FCWithChildren } from '@wix/challenges-web-library';
import { memberWebAppButtonClick as memberWebAppButtonClickV2 } from '@wix/bi-logger-challenges-member-web/v2';
import { ButtonNames } from '../../contexts/main/biInterfaces';

interface ISocialGroupLink
  extends Omit<React.HTMLAttributes<HTMLElement>, 'title'> {
  slug: string;
  showIcon?: boolean;
  biButtonName?: string;
  className?: string;
}

export const SocialGroupLink: FCWithChildren<ISocialGroupLink> = (props) => {
  const { groupPageUrl } = useSocialGroups();
  const {
    slug,
    showIcon = true,
    biButtonName = ButtonNames.GroupSmall,
  } = props;
  const bi = useBi();

  const { t } = useTranslation();

  const url = `${groupPageUrl?.url}/${slug}`;

  return (
    <span className={st(classes.root, props.className)} style={props.style}>
      {showIcon ? <GroupIcon className={classes.groupIcon} /> : null}
      <a
        data-hook="social-group-link"
        target="_blank"
        href={url}
        className={classes.link}
        rel="noreferrer"
        onClick={() => {
          bi.report(
            memberWebAppButtonClickV2({
              buttonName: biButtonName,
            }),
          );
        }}
      >
        {t('live-site.groups.link-cta')}
      </a>
    </span>
  );
};

SocialGroupLink.displayName = 'SocialGroup';
