import React from 'react';

import { MediaBoxSelection } from './MediaBoxSelection';
import { RadioButtonGroup, RadioButton } from 'wix-ui-tpa/cssVars';
import { FCWithChildren } from '@wix/challenges-web-library';
import { useExperiments } from '@wix/yoshi-flow-editor';

const getOptionsData = (labels: string) => {
  const longLabel =
    "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.\n";

  const getBaseLabel = (n: number) => {
    return labels === 'default' ? `Label ${n}` : longLabel;
  };

  return [
    {
      id: '0',
      label: labels === 'default' || labels === 'long' ? getBaseLabel(1) : '',
      media: {
        image: {
          altText: '',
          height: 4320,
          id: 'eb5b656c-0eb2-429b-87e5-4ab1e8e1b1d5',
          url: 'media/11062b_eb5b656c0eb2429b87e54ab1e8e1b1d5~mv2.jpg',
          width: 6480,
        },
      },
    },
    {
      id: '1',
      label: labels === 'default' || labels === 'long' ? getBaseLabel(2) : '',
      media: {
        image: {
          altText: '',
          height: 3939,
          id: 'd378d3cc-cda1-4bc0-bd2d-7e963dad3023',
          url: 'media/11062b_d378d3cccda14bc0bd2d7e963dad3023~mv2.jpg',
          width: 4476,
        },
      },
    },
    {
      id: '2',
      label: labels === 'default' || labels === 'long' ? getBaseLabel(3) : '',
      media: {
        image: {
          altText: '',
          height: 3808,
          id: 'a9989100-3457-4955-8c13-a401ad57cdd8',
          url: 'media/11062b_a9989100345749558c13a401ad57cdd8~mv2.jpeg',
          width: 5712,
        },
      },
    },
    {
      id: '3',
      label: labels === 'default' || labels === 'long' ? getBaseLabel(4) : '',
      media: {
        image: {
          altText: '',
          height: 1414,
          id: 'f57f6c4b-7a94-4ebb-9c47-c19e68e9f9e1',
          url: 'media/11062b_f57f6c4b7a944ebb9c47c19e68e9f9e1~mv2.jpg',
          width: 2121,
        },
      },
    },
  ];
};

export const MediaBoxSelectionTestWrapper: FCWithChildren<any> = () => {
  const { experiments } = useExperiments();
  const [columns, setColumns] = React.useState('single');
  const [fit, setFit] = React.useState('cover');
  const [labels, setLabels] = React.useState('default');
  const [isMultipleAnswers, setIsMultipleAnswers] = React.useState(false);

  if (!experiments.enabled('specs.programs.OOIShowMediaChoice')) {
    return null;
  }

  return (
    <>
      <div
        style={{
          margin: '40px 0',
        }}
      >
        <RadioButtonGroup
          name="mediaBoxColumns"
          value={columns}
          onChange={(value) => {
            setColumns(value);
          }}
        >
          <RadioButton value="single" label="Single Column" />
          <RadioButton value="two" label="Two Columns" />
          <RadioButton value="three" label="Three Columns" />
        </RadioButtonGroup>
      </div>

      <div
        style={{
          margin: '40px 0',
        }}
      >
        <RadioButtonGroup
          name="mediaBoxFit"
          value={fit}
          onChange={(value) => {
            setFit(value);
          }}
        >
          <RadioButton value="cover" label="Cover" />
          <RadioButton value="contain" label="Contain" />
        </RadioButtonGroup>
      </div>

      <div
        style={{
          margin: '40px 0',
        }}
      >
        <RadioButtonGroup
          name="mediaBoxLabels"
          value={labels}
          onChange={(value) => {
            setLabels(value);
          }}
        >
          <RadioButton value="empty" label="Empty" />
          <RadioButton value="default" label="Default" />
          <RadioButton value="long" label="Long" />
        </RadioButtonGroup>
      </div>

      <div
        style={{
          margin: '40px 0',
        }}
      >
        <RadioButtonGroup
          name="mediaBoxMultiple"
          value={isMultipleAnswers ? 'multi' : 'single'}
          onChange={(value) => {
            setIsMultipleAnswers(value === 'multi');
          }}
        >
          <RadioButton value="single" label="Single Answer" />
          <RadioButton value="multi" label="Multiple Answers" />
        </RadioButtonGroup>
      </div>

      <MediaBoxSelection
        label={'Test Media Selection'}
        isMultipleAnswers={isMultipleAnswers}
        columns={columns as any}
        fit={fit as any}
        options={getOptionsData(labels)}
      />
    </>
  );
};
