import React from 'react';
import { useSettings } from '@wix/tpa-settings/react';
import { useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';

import { OverviewIcon } from '../../../../../components-shared/OverviewIcon';

import { st, classes } from '../StepsListAsTiles/StepsListAsTiles.st.css';

import challengeSettings from '../../../settingsParams';
import {
  useCSSPBEnabled,
  useCSSPBStyle,
} from '../../../../../hooks/useCSSPBStyle';
import { FCWithChildren } from '@wix/challenges-web-library';
import { getBidirectionalAlignment } from '../../../../../utils/GetBidirectionalAlignment';

export interface IOverviewSidebar {
  isActive: boolean;
  onStepChosen: Function;
}

export const OverviewSidebar: FCWithChildren<IOverviewSidebar> = (props) => {
  const { t } = useTranslation();
  const settings = useSettings();
  const cssPBEnabled = useCSSPBEnabled();
  const getCSSPBStyle = useCSSPBStyle();
  const { isRTL } = useEnvironment();

  if (!cssPBEnabled && !settings.get(challengeSettings.overviewTabDisplay)) {
    return null;
  }

  return (
    <div
      className={st(classes.root, {
        align: getBidirectionalAlignment(
          settings.get(challengeSettings.sidebarLayoutTextAlignment),
          isRTL,
        ),
      })}
      style={getCSSPBStyle({ displayVar: '--opgOverviewTabDisplay' })}
      dir="auto"
    >
      <ul className={classes.stepsList}>
        <li
          className={`${classes.stepItem} ${classes.overviewItem} ${
            props.isActive ? classes.stepItemActive : ''
          }`}
          onClick={() => {
            props.onStepChosen(null);
          }}
        >
          <button className={classes.stepLink} onClick={() => {}}>
            <OverviewIcon />
            <div className={classes.gapBetweenTitleAndIcon} />
            <p className={`${classes.stepItemTitle}`}>
              {t('live.challenges-page-sidebar.overview-title')}
            </p>
          </button>
        </li>
      </ul>
    </div>
  );
};

OverviewSidebar.displayName = 'OverviewSidebarItem';
