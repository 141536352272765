import { intervalToDuration } from 'date-fns';

export function formatProgramVideoDuration(ms) {
  const { hours, minutes, seconds } = intervalToDuration({ start: 0, end: ms });
  const zeroPad = (num) => String(num).padStart(2, '0');
  // align with mobile app format: [hh]:[mm]:[ss]
  return `${hours ? zeroPad(hours) + ':' : ''}${zeroPad(minutes)}:${zeroPad(
    seconds || 1,
  )}`;
}
