import React from 'react';

import { classes } from './CompletedIcon.st.css';
import { FCWithChildren } from '@wix/challenges-web-library';
import { useTranslation } from '@wix/yoshi-flow-editor';

export const CompletedIcon: FCWithChildren = () => {
  const { t } = useTranslation();
  return (
    <div className={classes.root}>
      <span className={classes.rootCircle}></span>
    </div>
  );
};
