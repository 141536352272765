import React from 'react';

import { Challenge } from '@wix/ambassador-challenges-v1-challenge/types';
import { useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';
import { useSettings } from '@wix/tpa-settings/react';
import { dataHooks } from '@wix/data-hooks';

import {
  FCWithChildren,
  serverTimelineTypeToClientTimelineString,
} from '@wix/challenges-web-library';

import { ChallengeOverviewSection } from '../ChallengeOverviewSection';
import { ChallengeDetails } from '../../../../../components-shared/ChallengeDetails';
import { MediaCover } from '../../../../../components-shared/MediaCover';
import {
  RewardInfo,
  RewardInfoCompleted,
} from '../../../../../components-shared/RewardInfo';
import { ChallengeStats } from '../ChallengeStats';

import { classes, st } from './ChallengeOverview.st.css';
import utils from '../../views/utils';
import settingsParams from '../../../settingsParams';
import { Fullscreen } from '../Fullscreen/Fullscreen';
import { SettingsKeysAbbreviation } from '../../../Settings/constants/settingsKeysAbbreviation';
import { useUser } from '../../../../../contexts/User/UserContext';
import { isUserCompleted } from '../../../../../contexts/User/helpers/userTypeHandlers';
import {
  useCSSPBEnabled,
  useCSSPBStyle,
} from '../../../../../hooks/useCSSPBStyle';
import { VisitorPageInstructors } from '../../../../ChallengesPage/Widget/views/VisitorPage/components/sections/VisitorPageInstructors';
import { getProgramAltImageText } from '../../../../../selectors/getProgramImageAltText';
import { useChallengeData } from '../../../../../contexts/storage-contexts/Challenge';
import challengeSettings from '../../../../ChallengesPage/settingsParams';
import { SocialGroupLink } from '../../../../../components-shared/SocialGroup/SocialGroupLink';
import { getBidirectionalAlignment } from '../../../../../utils/GetBidirectionalAlignment';

export interface IChallengeOverviewProps {
  className?: string;
  challenge: Challenge;
  prefix?: React.ReactElement;
  goToCurrentStep?(): void;
  bottomControls?: React.ReactNode;
  pageRef?: React.RefObject<HTMLElement>;
}

export const challengeOverviewDataHooks = dataHooks<{
  main;
  media;
  author;
}>('challenge-overview');

const Duration: FCWithChildren<Partial<IChallengeOverviewProps>> = ({
  challenge,
}) => {
  const { t } = useTranslation();
  const settings = useSettings();
  const { language } = useEnvironment();

  const { showHeaderDate } = utils.getBaseViewOptionsForSidebar(
    challenge,
    settings,
  );

  const durationString = serverTimelineTypeToClientTimelineString(
    challenge?.settings?.timelineType as any,
    language,
    t,
    'challenge-card.duration-string.ongoing',
    `challenge-card.duration-string.flexible.days_icu`,
    `challenge-card.duration-string.flexible.weeks_icu`,
    'challenge-card.duration-string.no-limit',
  );

  return showHeaderDate ? (
    <p className={classes.duration}>{durationString}</p>
  ) : null;
};

const OverviewMedia: FCWithChildren<Partial<IChallengeOverviewProps>> = ({
  challenge,
}) => {
  const isShouldBeRendered = challenge?.settings?.description?.media;
  const settings = useSettings();
  const alignment = settings.get(challengeSettings.sidebarLayoutTextAlignment);

  return isShouldBeRendered ? (
    <MediaCover
      fit="contain"
      alignment={alignment}
      altText={getProgramAltImageText(challenge)}
      maxHeight={500}
      media={challenge.settings.description.media}
      dataHook={challengeOverviewDataHooks.media()}
    />
  ) : null;
};

const Details: FCWithChildren<Partial<IChallengeOverviewProps>> = ({
  challenge,
}) => {
  const { isMobile } = useEnvironment();

  return (
    <ChallengeDetails
      isMobile={isMobile}
      className={classes.challengeDetails}
      details={challenge?.settings?.description?.details}
    />
  );
};

const Group: FCWithChildren<
  Partial<IChallengeOverviewProps> & {
    Button?: FCWithChildren;
  } & React.HtmlHTMLAttributes<HTMLElement>
> = ({ style }) => {
  const { t } = useTranslation();
  const { challengeData } = useChallengeData();
  const { socialGroupId } = challengeData?.challenge?.settings;

  return (
    <div style={style} className={classes.socialGroup}>
      <p dir="auto" className={classes.groupText}>
        <span>{t('live-site.groups.section-subtitle.joined')}</span>
        <SocialGroupLink
          slug={socialGroupId}
          showIcon={false}
          biButtonName="GroupButton_overview"
          className={classes.groupsOverview}
        />
      </p>
    </div>
  );
};

const Reward: FCWithChildren<Partial<IChallengeOverviewProps>> = ({
  challenge,
}) => {
  const { participant } = useUser();
  const settings = useSettings();
  const { isRTL } = useEnvironment();

  const alignment = getBidirectionalAlignment(
    utils.getContentAlignByType(settings),
    isRTL,
  );

  if (
    isUserCompleted(participant?.transitions[0]?.state) ||
    participant?.certificate
  ) {
    return (
      <RewardInfoCompleted
        className={classes.rewardInfo}
        rewards={challenge.settings.rewards}
      />
    );
  }

  return (
    <RewardInfo
      className={st(classes.rewardInfo, {
        alignment,
      })}
      rewards={challenge.settings.rewards}
    />
  );
};

const Stats: FCWithChildren<Partial<IChallengeOverviewProps>> = ({
  challenge,
}) => {
  return (
    <ChallengeStats className={classes.challengeStats} challenge={challenge} />
  );
};

const Instructors: FCWithChildren<Partial<IChallengeOverviewProps>> = () => {
  const settings = useSettings();
  const { isRTL } = useEnvironment();

  return (
    <VisitorPageInstructors
      alignment={utils.getContentAlignByType(settings, isRTL)}
      pageType="participant"
    />
  );
};

export const ChallengeOverview: FCWithChildren<IChallengeOverviewProps> = ({
  className = '',
  prefix,
  challenge,
  pageRef,
}: IChallengeOverviewProps) => {
  const { t } = useTranslation();
  const { isMobile, isRTL } = useEnvironment();
  const settings = useSettings();
  const alignment = utils.getContentAlignByType(settings, isRTL);

  const {
    challengeData: { instructorsData },
    requestInstructorsList,
  } = useChallengeData();
  const cssPBEnabled = useCSSPBEnabled();
  const isAuthorSectionVisible =
    (cssPBEnabled || settings.get(settingsParams.displayInstructors)) &&
    !!instructorsData?.instructors?.length;

  const getCSSPBStyle = useCSSPBStyle();
  const authorSectionTitle = settings.get(
    settingsParams[SettingsKeysAbbreviation.visitorPageInstructorTitle],
  );

  const showRewards =
    cssPBEnabled || settings.get(settingsParams.displayRewards);
  const displayRewards = !!challenge?.settings?.rewards?.length && showRewards;
  const showGroup = cssPBEnabled || settings.get(settingsParams.displayGroup);

  React.useEffect(() => {
    if (!instructorsData) {
      void requestInstructorsList?.(challenge?.id);
    }
  }, []);

  return (
    <div
      className={`${st(classes.root, {
        mobile: isMobile,
        alignment,
      })} ${className}`}
      data-hook={challengeOverviewDataHooks.main()}
    >
      {prefix || null}

      <>
        <Fullscreen pageRef={pageRef} />
        <Duration challenge={challenge} />
        <Stats challenge={challenge} />
        <OverviewMedia challenge={challenge} />
        {!!challenge?.settings?.description?.details && (
          <ChallengeOverviewSection
            title={t('live.challenges-page-sidebar.about-title')}
          >
            <Details challenge={challenge} />
          </ChallengeOverviewSection>
        )}

        {displayRewards ? (
          <ChallengeOverviewSection
            title={t('challenge.overview.reward-title')}
            style={getCSSPBStyle({ displayVar: '--opgDisplayRewards' })}
          >
            <Reward challenge={challenge} />
          </ChallengeOverviewSection>
        ) : null}

        {showGroup && challenge?.settings?.socialGroupId ? (
          <ChallengeOverviewSection
            title={t('live-site.groups.section-title')}
            style={getCSSPBStyle({ displayVar: '--opgDisplayGroup' })}
          >
            <Group />
          </ChallengeOverviewSection>
        ) : null}

        {isAuthorSectionVisible ? (
          <ChallengeOverviewSection
            title={authorSectionTitle as string}
            dataHook={challengeOverviewDataHooks.author()}
            style={getCSSPBStyle({ displayVar: '--opgDisplayInstructors' })}
          >
            <Instructors />
          </ChallengeOverviewSection>
        ) : null}
      </>
    </div>
  );
};
ChallengeOverview.displayName = 'ChallengeOverview';
