import React from 'react';
import { ToastContextConsumer } from './ToastContext';
import { FCWithChildren } from '@wix/challenges-web-library';

export function withToast<T>(Component: any): FCWithChildren<T> {
  return (props) => {
    return (
      <ToastContextConsumer>
        {(value) => {
          return <Component {...(props as T)} {...value} />;
        }}
      </ToastContextConsumer>
    );
  };
}
