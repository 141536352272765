import React from 'react';

import { CantJoinModal } from '../../../../../components/CantJoinModal';
import { useVisitorPageBase } from '../../../contexts/VisitorPageBase/VisitorPageBaseContext';
import { FCWithChildren } from '@wix/challenges-web-library';

export const VisitorPageCantJoinModal: FCWithChildren = () => {
  const { isCantJoinModalOpened, onCantJoinSecondaryClick, onCantJoinClose } =
    useVisitorPageBase();

  return (
    <CantJoinModal
      isOpen={isCantJoinModalOpened}
      onSecondaryLinkClick={onCantJoinSecondaryClick as any}
      onClose={onCantJoinClose as any}
    />
  );
};
