import React from 'react';

import { useSettings } from '@wix/tpa-settings/react';
import { Video } from '../../../../../../../components-shared/Video';
import challengeSettings from '../../../../../settingsParams';
import {
  imageDataToMediaUrl,
  IImageData,
  FCWithChildren,
} from '@wix/challenges-web-library';

import { st, classes } from './VisitorPageMedia.st.css';
import { useCSSPBStyle } from '../../../../../../../hooks/useCSSPBStyle';
import { getProgramAltImageText } from '../../../../../../../selectors/getProgramImageAltText';
import { useChallengeData } from '../../../../../../../contexts/storage-contexts/Challenge';
import { visitorPageMediaDataHooks } from './VisitorPageMediaDataHooks';

interface IVisitorPageMediaProps {
  className?: string;
  isInline: boolean;
}

export const VisitorPageMedia: FCWithChildren<IVisitorPageMediaProps> = ({
  isInline,
  className,
}) => {
  const settings = useSettings();
  const {
    challengeData: { challenge },
  } = useChallengeData();
  const challengeDescriptionMedia = challenge?.settings?.description?.media;
  const isMockImage = !challengeDescriptionMedia && isInline;
  const getStyle = useCSSPBStyle();
  const cssPBStyle = getStyle({ displayVar: '--opgDisplayHeaderImage' });
  const displayHeader = cssPBStyle
    ? true
    : settings.get(challengeSettings.displayHeaderImage);
  const isShouldBeRendered =
    displayHeader && (challengeDescriptionMedia || isMockImage);

  const isVideo = challengeDescriptionMedia?.video;

  return isShouldBeRendered ? (
    <figure
      className={`${st(classes.root, {
        inline: isInline,
        imageRatio: settings.get(challengeSettings.imageRatio),
        cropSelection: settings.get(challengeSettings.cropSelection),
      })} ${className}`}
      data-hook={visitorPageMediaDataHooks.desktopMedia()}
      style={cssPBStyle}
    >
      <div className={classes.imageRatioBox}>
        <div className={classes.imageRatioBoxInner}>
          {/* eslint-disable-next-line no-nested-ternary */}
          {isVideo ? (
            <Video
              altText={getProgramAltImageText(challenge)}
              className={classes.mediaItem}
              thumbnail={challengeDescriptionMedia?.video?.thumbnail}
              video={challengeDescriptionMedia?.video}
            />
          ) : isMockImage ? (
            <div className={classes.mediaMockImage} />
          ) : (
            <img
              alt={getProgramAltImageText(challenge)}
              className={classes.mediaItem}
              width={1000}
              height={800}
              src={imageDataToMediaUrl({
                ...(challengeDescriptionMedia?.image as IImageData),
                width: 1000,
                height: 800,
              })}
            />
          )}
        </div>
      </div>
    </figure>
  ) : null;
};
