import React from 'react';

import { SocialShare } from '../../../../../components-shared/SocialShare';

import { useTranslation, useEnvironment } from '@wix/yoshi-flow-editor';
import { useChallengeData } from '../../../../../contexts/storage-contexts/Challenge';
import { useLocation } from '../../../../../contexts/Location/LocationContext';

import { TextAlignment } from '../../../Settings/challengeSettings/challengeSettings.types';
import { st, classes } from './ChallengeShare.st.css';
import { FCWithChildren } from '@wix/challenges-web-library';
import { getBidirectionalAlignment } from '../../../../../utils/GetBidirectionalAlignment';

export interface IChallengeShareProps {
  className?: string;
  alignment?: TextAlignment;
}

export const ChallengeShare: FCWithChildren<IChallengeShareProps> = ({
  className = null,
  alignment = TextAlignment.Left,
}) => {
  const { t } = useTranslation();
  const { isMobile, isRTL } = useEnvironment();
  const { challengeData: { challenge = {} } = {} } = useChallengeData();
  const { location: { url = '' } = {} } = useLocation();

  return (
    <SocialShare
      className={st(
        classes.root,
        {
          mobile: isMobile,
          alignment: getBidirectionalAlignment(alignment, isRTL),
        },
        className,
      )}
      shareTitle={t('live.challenges-page.social-share-challenge.share-title', {
        challengeName: challenge?.settings.description.title.trim(),
      })}
      shareUrl={url}
      originalUrl={url}
    />
  );
};
