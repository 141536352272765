import { useControllerData } from '../main/controllerContext';

export const useLocalStorage = () => {
  const props = useControllerData();
  return {
    requestItem: props.requestItem,
    setItem: props.setItem,
    storage: props.storage,
  };
};
