import { useControllerData } from '../../main/controllerContext';
import { IBadgesContext } from './interfaces';

export const useBadges = (): IBadgesContext => {
  const props = useControllerData();

  return {
    badges: props.challengeData?.badgesData?.badges || [],
  };
};
