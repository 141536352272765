import {
  IUserPaidPlan,
  serverPricingToClientPriceAndCurrencyString,
  TranslateFunction,
} from '@wix/challenges-web-library';

function getPaidPlanWithLowestPrice(paidPlans: IUserPaidPlan[]) {
  if (!paidPlans) {
    return null;
  }
  return paidPlans.reduce((paidPlanWithLowerPrice, paidPlan) => {
    if (
      !paidPlanWithLowerPrice ||
      parseFloat(paidPlanWithLowerPrice?.paymentOptions?.price?.amount) >
        parseFloat(paidPlan?.paymentOptions?.price?.amount)
    ) {
      return paidPlan;
    }
    return paidPlanWithLowerPrice;
  }, null);
}

export function getPaidPlanPrice(
  paidPlans: IUserPaidPlan[],
  t: TranslateFunction,
) {
  const paidPlanWithLowestPrice = getPaidPlanWithLowestPrice(paidPlans);

  if (!paidPlanWithLowestPrice) {
    return null;
  }

  const price = serverPricingToClientPriceAndCurrencyString({
    singlePayment: {
      price: paidPlanWithLowestPrice?.paymentOptions?.price,
    },
  });

  if (paidPlanWithLowestPrice?.paymentOptions?.price?.amount === '0') {
    return t('pricing.payment-option.subscription.free');
  }

  if (paidPlanWithLowestPrice?.paymentOptions?.price?.amount === null) {
    return '';
  }

  const additionalFees = (
    paidPlanWithLowestPrice.paymentOptions as any
  ).additionalFees
    ?.map(
      ({ name, amount }) =>
        `${serverPricingToClientPriceAndCurrencyString({
          singlePayment: {
            price: {
              amount,
              currency: paidPlanWithLowestPrice.paymentOptions.price.currency,
            },
          },
        })} ${name}`,
    )
    .join(', ');
  const formattedAdditionalFees = additionalFees ? ' + ' + additionalFees : '';

  if (
    !paidPlanWithLowestPrice?.paymentOptions?.recurring ||
    // PPs add configuration of period. To do not add new l10n keys we won't
    // show period of recurring for such cases
    paidPlanWithLowestPrice?.paymentOptions?.validFor?.period?.amount > 1
  ) {
    return (
      t('pricing.payment-option.subscription.one-time-payment_new-syntax', {
        price,
      }) + formattedAdditionalFees
    );
  }

  return (
    t(
      `pricing.payment-option.subscription.recurring.${paidPlanWithLowestPrice?.paymentOptions?.validFor?.period?.unit?.toLowerCase()}_new-syntax`,
      {
        price,
      },
    ) + formattedAdditionalFees
  );
}

export function getPaidPlanStartPrice(
  paidPlans: IUserPaidPlan[],
  t: TranslateFunction,
) {
  if (
    paidPlans.length === 1 &&
    getPaidPlanWithLowestPrice(paidPlans)?.paymentOptions?.price?.amount === '0'
  ) {
    return t('challenge-card.pricing.free');
  }

  if (
    paidPlans.length === 1 ||
    getPaidPlanWithLowestPrice(paidPlans)?.paymentOptions?.price?.amount === '0'
  ) {
    return getPaidPlanPrice(paidPlans, t);
  }

  return t('pricing.payment-option.starts-from_new-syntax', {
    price: getPaidPlanPrice(paidPlans, t),
    interpolation: {
      escapeValue: false,
    },
  });
}
