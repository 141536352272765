import React, { useEffect, useCallback } from 'react';

import { Back } from '../icons/Back';
import { TextButton } from '@wix/wix-base-ui';

import { useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';
import { useSidebarLayoutBase } from '../../views/SidebarLayout/contexts/SidebarLayoutBase/SidebarLayoutBaseContext';
import { useSettings } from '@wix/tpa-settings/react';
import {
  useFocusManager,
  FocusPoint,
} from '../../../../../contexts/FocusManager';

import { classes, st } from './StepViewBreadcrumbs.st.css';
import utils from '../../views/utils';

export interface IStepViewBreadcrumbsProps {
  goToMobileList(): void;
}

export const StepViewBreadcrumbs: React.FunctionComponent<
  IStepViewBreadcrumbsProps
> = ({ goToMobileList }: IStepViewBreadcrumbsProps) => {
  const { t } = useTranslation();
  const { currentStep } = useSidebarLayoutBase();
  const { isMobile, isRTL } = useEnvironment();
  const settings = useSettings();
  const alignment = utils.getContentAlignByType(settings, isRTL);
  const backButtonRef: React.RefObject<HTMLButtonElement> = React.createRef();
  const { moveFocusToPoint } = useFocusManager();

  useEffect(() => {
    const backButtonDOM = backButtonRef.current?.querySelector('button');

    if (backButtonDOM) {
      moveFocusToPoint(backButtonDOM, FocusPoint.Top);
    }
  }, [backButtonRef.current, moveFocusToPoint]);

  const onBackClick = useCallback(() => {
    goToMobileList();

    setTimeout(() => {
      if (currentStep?.id) {
        const btnDOM = document.querySelector(
          `li[data-id="${currentStep?.id}"] button`,
        );

        if (btnDOM) {
          btnDOM.scrollIntoView({ behavior: 'smooth' });
          (btnDOM as any).focus();
        }
      }
    }, 100);
  }, [currentStep?.id, goToMobileList]);

  return (
    <div
      className={st(classes.root, {
        mobile: isMobile,
        alignment,
      })}
      dir={isRTL ? 'rtl' : 'auto'}
    >
      {isMobile ? (
        <div ref={backButtonRef as any}>
          <TextButton
            className={classes.mobileBackButton}
            onClick={onBackClick}
            enableEllipsis={false}
            weight="thin"
          >
            <Back className={classes.backButtonSvg} />
            <span className={classes.backButtonText} dir="auto">
              {t('live.challenges-page-sidebar.mobile-back-button')}
            </span>
          </TextButton>
        </div>
      ) : null}
    </div>
  );
};
StepViewBreadcrumbs.displayName = 'StepViewBreadcrumbs';
