import { Reward } from '@wix/ambassador-challenges-v1-challenge/types';

const getRewardWithCertificate = (rewards: Reward[]) => {
  return rewards.filter((reward) => !!reward.certificate)[0];
};

export const getCertificateTrigger = (rewards: Reward[]) => {
  return getRewardWithCertificate(rewards)?.trigger;
};

const getRewardWithBadges = (rewards: Reward[]) => {
  return rewards.filter((reward) => reward?.badgeIds?.length > 0)[0];
};

export const getProgramBadgesIds = (rewards: Reward[]) => {
  const rewardWithBadges = getRewardWithBadges(rewards);

  if (rewardWithBadges) {
    return rewardWithBadges.badgeIds;
  }
  return [];
};

export const getBadgesTrigger = (rewards: Reward[]) => {
  const rewardWithBadges = getRewardWithBadges(rewards);
  if (rewardWithBadges) {
    return rewardWithBadges.trigger;
  }
  return null;
};
