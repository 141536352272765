import React from 'react';

import { classes } from './LockedIcon.st.css';
import { FCWithChildren } from '@wix/challenges-web-library';

export const LockedIcon: FCWithChildren = () => {
  return (
    <div className={classes.root}>
      <span className={classes.rootCircle}></span>
    </div>
  );
};
