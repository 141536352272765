import React from 'react';
import { classes, st } from './box.st.css';
import { FCWithChildren } from '@wix/challenges-web-library';

type TagNames = 'section' | 'div';

export type BoxProps = {
  children: React.ReactNode;
  gap?: 3 | 6; // extend if needed
  direction?: 'column' | 'row';
  alignItems?: 'center' | 'flex-start';
  justifyContent?: 'center' | 'flex-start' | 'flex-end';
  marginTop?: 1 | 2 | 6 | 7;
  paddingLeft?: 7 | 12;
  paddingRight?: 7 | 12;
  textAlign?: 'center';
  tag?: TagNames;
  className?: string;
};

export const Box: FCWithChildren<BoxProps> = (props) => {
  const TagName = props.tag || 'div';

  return (
    <TagName
      className={st(
        classes.box,
        {
          gap: props.gap,
          alignItems: props.alignItems,
          justifyContent: props.justifyContent,
          marginTop: props.marginTop,
          paddingLeft: props.paddingLeft,
          paddingRight: props.paddingRight,
          direction: props.direction,
          textAlign: props.textAlign,
        },
        props.className,
      )}
    >
      {props.children}
    </TagName>
  );
};
