import { Participant } from '../../types/v3Types';

export const getParticipantId = (participant: Participant) => {
  return participant?.id;
};

export const getParticipantIdFromInitialProps = (initialProps: any) => {
  return getParticipantId(
    initialProps?.participant || initialProps?.participantV3,
  );
};
