import { useControllerData } from '../main/controllerContext';

export const useSocialGroups = () => {
  const props = useControllerData();
  return {
    isGroupsInstalled: props.isGroupsInstalled,
    groupPageUrl: props.groupPageUrl,
    socialGroup: props.challengeData?.groupData?.group || null,
  };
};
