import React from 'react';
import { LeaveModalType } from './LeaveChallengeModalProvider';

export type ShowModalParams = {
  participantId: string;
  challengeId?: string;
  modalType?: LeaveModalType;
  isPaidChallenge?: boolean;
};

export interface ILeaveChallengeModalContext {
  showModal(
    opts: ShowModalParams,
    onClose?: () => void,
    onLeave?: () => void,
  ): void;
}

export const LeaveChallengeModalContext =
  React.createContext<ILeaveChallengeModalContext>({
    showModal: null,
  });

export const useLeaveChallengeModal = () =>
  React.useContext(LeaveChallengeModalContext);
export const withLeaveChallengeModal = <P extends ILeaveChallengeModalContext>(
  Component: React.ComponentType<P>,
): React.FC<Omit<P, 'showModal'>> => {
  return (props) => {
    return (
      <LeaveChallengeModalContext.Consumer>
        {(value) => {
          return <Component {...(props as P)} {...value} />;
        }}
      </LeaveChallengeModalContext.Consumer>
    );
  };
};
