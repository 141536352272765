import {
  State as ParticipantState,
  Participant,
} from '@wix/ambassador-challenges-v1-participant/types';

export function isOfflinePaymentStarted(participant: Participant) {
  return (
    participant?.transitions?.[0]?.offlineTrxId ||
    participant?.transitions?.[0]?.state ===
      ParticipantState.PAYMENT_STARTED_OFFLINE
  );
}
