import { Challenge } from '@wix/ambassador-challenges-v1-challenge/types';
import { Participant } from '../../types/v3Types';
import { getProgress } from './getProgress';

export function isProgramProgressReachedEnd(
  challenge: Challenge,
  participant: Participant,
) {
  return !!challenge && getProgress(participant) === 100;
}
