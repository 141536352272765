import { ParticipantStepState } from '@wix/ambassador-challenges-v1-participant/types';
import { format, addDays } from 'date-fns';
import { getDelay } from '../../selectors/step';
import {
  StepWithDelay,
  ResolvedStep,
  Status,
  ParticipantStep,
  ParticipantStepV3,
} from '../../types/v3Types';

const currDate = new Date();

interface Params {
  step: StepWithDelay;
  resolvedStep?: ResolvedStep;
  initialDate?: Date;
  dateFrame?: ParticipantStep['dateFrame'];
  locale?: any;
}

const transformStep = ({
  step,
  initialDate = null,
  dateFrame = null,
}: Params): ParticipantStep => {
  const delay = getDelay(step);
  if (!dateFrame) {
    const startDate = addDays(initialDate || currDate, delay);
    const finishDate = addDays(initialDate || currDate, delay + 1);

    dateFrame = {
      start: format(startDate, 'yyyy-MM-dd'),
      finish: format(finishDate, 'yyyy-MM-dd'),
    };
  }
  return {
    id: step?.id,
    dateFrame,
    source: { ...step },
    transitions: [{ state: ParticipantStepState.RUNNING }],
  };
};

export const resolutionFromResolvedStep = (
  resolvedStep: ResolvedStep,
): ParticipantStep['transitions'] => {
  switch (resolvedStep.status) {
    case Status.COMPLETED:
      return [{ state: ParticipantStepState.COMPLETED }];
    case Status.FAILED:
      return [{ state: ParticipantStepState.FAILED }];
    default:
      return [{ state: ParticipantStepState.RUNNING }];
  }
};

const transitionFromStep = (step: ParticipantStep) => {
  // PENDING if the step is not started yet
  if (new Date(step.dateFrame.start) > currDate) {
    return [{ state: ParticipantStepState.PENDING }];
  }
  // OVERDUE if the step is started and the finish date is in the past
  const finish = new Date(step.dateFrame.finish);
  if (finish < currDate) {
    return [{ state: ParticipantStepState.OVERDUE }];
  }
  return step.transitions;
};

export function quizSubmissionFromResolvedStep({
  quizSubmissionId,
  earnedScore,
  passingGrade,
}: ResolvedStep): ParticipantStepV3['quizSubmission'] {
  if (!quizSubmissionId) {
    return undefined;
  }
  return {
    quizSubmissionId,
    score: earnedScore,
    passingGrade,
  };
}

export const toParticipantStepV3 = ({
  step,
  resolvedStep,
  initialDate = null,
  dateFrame = null,
  locale = null,
}: Params): ParticipantStep => {
  const _step = transformStep({
    step,
    initialDate,
    locale,
    dateFrame,
  }) as ParticipantStepV3;
  if (dateFrame) {
    _step.dateFrame = dateFrame;
  }
  if (resolvedStep) {
    _step.transitions = resolutionFromResolvedStep(resolvedStep);
    _step.quizSubmission = quizSubmissionFromResolvedStep(resolvedStep);
  } else {
    _step.transitions = transitionFromStep(_step);
  }

  return _step;
};

export const toParticipantSteps = ({
  ownerSteps,
  initialDate = null,
}: {
  ownerSteps: { steps: StepWithDelay[] };
  initialDate?: Date;
}): ParticipantStep[] => {
  return ownerSteps.steps.map((step) => transformStep({ step, initialDate }));
};
