interface OrderItemsArgs<Item, OrderedItem> {
  items: Item[];
  order: OrderedItem[];

  predicate(item: Item, orderedItem: OrderedItem): boolean;
}

export const getOrderedItems = <Item, OrderedItem = Item>({
  items,
  order,
  predicate,
}: OrderItemsArgs<Item, OrderedItem>): any[] => {
  if (!order?.length) {
    return items;
  }
  const orderedItems = order.map((orderedItem) => {
    const item = items.find((i) => predicate(i, orderedItem));

    if (!item) {
      return;
    }

    return item;
  });

  const tail = items.filter((item) => {
    const isInOrder = order.find((orderedItem) => predicate(item, orderedItem));

    return !isInOrder;
  });

  return [...orderedItems.filter(Boolean), ...tail];
};

export function getShortId(id: string) {
  return id.substring(0, 5);
}
