export enum SettingsKeysAbbreviation {
  visitorPageToggleAboutTitle = 'vp1',
  visitorPageAboutTitle = 'vp2',
  visitorPageToggleAboutJoinButtonTitle = 'vp3',
  visitorPageAboutJoinButtonTitle = 'vp4',
  visitorPageToggleGroupDiscussionTitle = 'vp5',
  visitorPageGroupDiscussionTitle = 'vp6',
  visitorPageShowGroupImage = 'vp7',
  visitorPageGroupDiscussionShowInSidebar = 'vp8',
  visitorPageToggleInstructorTitle = 'vp9',
  visitorPageInstructorTitle = 'vp10',
  visitorPageAboutInstructorImageShape = 'vp11',
  visitorPageInstructorShowInSidebar = 'vp12',
  visitorPageTogglePriceTitle = 'vp17',
  visitorPagePriceTitle = 'vp18',
  visitorPageTogglePriceJoinButtonTitle = 'vp19',
  visitorPagePriceJoinButtonTitle = 'vp20',
  visitorPagePriceShowInSidebar = 'vp21',
  visitorPageToggleShareTitle = 'vp22',
  visitorPageShareTitle = 'vp23',
  visitorPageShareShowInSidebar = 'vp24',
  visitorPageToggleStartDayTitle = 'vp25',
  visitorPageStartDayTitle = 'vp26',
  visitorPageToggleStartDayJoinButtonTitle = 'vp27',
  visitorPageStartDayJoinButtonTitle = 'vp28',
  visitorPageStartDayShowInSidebar = 'vp29',
  visitorPageBodySectionsOrder = 'vp30',
  visitorPageSidebarSectionsOrder = 'vp31',
  visitorPageAboutShowInSidebar = 'vp32',
  visitorPageOverviewShowInSidebar = 'vp33',
}
