import { IUserPaidPlan, TranslateFunction } from '@wix/challenges-web-library';

export function getPaidPlansJoinedName(
  t: TranslateFunction,
  challengePaidPlans: IUserPaidPlan[] = [],
  joinedKey: string,
  forActivePlansOnly: boolean = false,
) {
  const plans = forActivePlansOnly
    ? challengePaidPlans.filter((pp) => pp.visible !== false)
    : challengePaidPlans;

  if (!plans.length) {
    return '';
  }

  return plans.length === 1
    ? plans[0]?.details?.name || ''
    : t(joinedKey, {
        count: plans.length,
      });
}
