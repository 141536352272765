interface iQuery {
  [index: string]: string;
}

export const isForcedPreviewVisitor = (query: iQuery): boolean => {
  return query?.forcedPreviewAs === 'Visitor';
};

export const isForcedPreviewParticipant = (query: iQuery): boolean => {
  return query?.forcedPreviewAs === 'Participant';
};

export const isForcedPreview = (query: iQuery): boolean => {
  return isForcedPreviewVisitor(query) || isForcedPreviewParticipant(query);
};
