import { Challenge } from '@wix/ambassador-challenges-v1-challenge/types';
import {
  getChallengePaidPlans,
  getChallengeVisiblePaidPlans,
  isChallengeFree,
  IUserPaidPlan,
} from '@wix/challenges-web-library';
import { MemberOrdersByPlan } from '@wix/ambassador-pricing-plan-benefits-server/types';
import { getChallengeEligiblePaidPlans } from '../components/ChallengesPage/Widget/components/Pricing/helpers/getChallengeEligiblePaidPlans';

export function getPricingViewOptions(
  challenge: Challenge,
  userPaidPlans: IUserPaidPlan[],
  eligiblePlans: MemberOrdersByPlan[] = [],
  visiblePlansOnly: boolean = false,
  includeEligiblePlans: boolean = true,
) {
  const challengePricing = challenge.settings.pricing;
  const challengeEligiblePaidPlans = getChallengeEligiblePaidPlans(
    eligiblePlans || [],
    userPaidPlans || [],
    challenge?.id,
  );
  const challengePaidPlans = (
    (visiblePlansOnly
      ? getChallengeVisiblePaidPlans(challenge.id, userPaidPlans)
      : getChallengePaidPlans(challenge.id, userPaidPlans)) || []
  ).filter((plan) => {
    if (includeEligiblePlans) {
      return true;
    } else {
      const isIncludedInEligiblePlans = challengeEligiblePaidPlans.find(
        (elPlan) => elPlan.id === plan.id,
      );

      return !isIncludedInEligiblePlans;
    }
  });
  const isFree = isChallengeFree(
    challengePricing as any,
    challengePaidPlans.length,
  );
  const isHasSinglePayment = !!challengePricing.singlePayment;
  const isHasPaidPlans = challengePaidPlans.length;

  return {
    challengePaidPlans,
    isFree,
    isHasSinglePayment,
    isHasPaidPlans,
  };
}
