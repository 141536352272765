import { challengeStyles } from '../../challengeStyles';

export interface IFont {
  label: string;
  setting: any;
  min?: number;
  max?: number;
  experiment?: string;
}

export enum IListMobileScreenFromSettings {
  Schedule = 'Schedule',
  Overview = 'Overview',
}

export enum ISidebarMobileScreenFromSettings {
  List = 'List',
  Schedule = 'Schedule',
  Overview = 'Overview',
}

export interface IFontSection {
  label: string;
  items: IFont[];
  listMobileScreen?: IListMobileScreenFromSettings;
  sidebarMobileScreen?: ISidebarMobileScreenFromSettings;
  experiment?: string;
}
export const participantSidebarDesignSettings: IFontSection[] = [
  {
    label: 'participant-page.settings.design.mobile.header-title',
    items: [
      {
        label:
          'editor.challenge.settings.design.text-style.sidebar.challenge-name',
        setting: challengeStyles.mobSidebarLayoutSidebarChallengeNameFontSize,
        max: 40,
      },
      {
        label:
          'editor.challenge.settings.design.text-style.sidebar.section-name',
        setting: challengeStyles.mobSidebarLayoutSidebarSectionNameFontSize,
        max: 40,
      },
      {
        label: 'editor.challenge.settings.design.text-style.sidebar.step-name',
        setting: challengeStyles.mobSidebarLayoutSidebarStepNameFontSize,
        max: 32,
      },
    ],
    sidebarMobileScreen: ISidebarMobileScreenFromSettings.List,
  },
  {
    label: 'participant-page.settings.design.mobile.body-title',
    items: [
      {
        label: 'editor.challenge.settings.design.text-style.sidebar.step-name',
        setting: challengeStyles.mobSidebarLayoutBodyStepNameFontSize,
        max: 40,
      },
      {
        label: 'participant-page.settings.design.mobile.body-text',
        setting: challengeStyles.mobSidebarLayoutStepDescriptionFontSize,
      },
    ],
    sidebarMobileScreen: ISidebarMobileScreenFromSettings.Schedule,
  },
  {
    label: 'participant-page.settings.design.mobile.overview-title',
    items: [
      {
        label: 'editor.challenge.settings.design.text-style.sidebar.title',
        setting: challengeStyles.mobSectionTitleSize,
        max: 40,
      },
      {
        label: 'participant-page.settings.design.mobile.overview-description',
        setting: challengeStyles.mobSidebarLayoutOverviewDescriptionFontSize,
      },
    ],
    sidebarMobileScreen: ISidebarMobileScreenFromSettings.Overview,
  },
];
