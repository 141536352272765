import React from 'react';
import { classes } from './singlePricingOption.st.css';
import { FCWithChildren } from '@wix/challenges-web-library';

export interface ISinglePricingOptionProps {}

export const SinglePricingOption: FCWithChildren<ISinglePricingOptionProps> = (
  props,
) => {
  return <div className={classes.root}>{props.children}</div>;
};
