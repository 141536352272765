import React from 'react';
import { Popover, ActionsMenuLayout } from 'wix-ui-tpa/cssVars';
import { Ellipses } from '../icons/Ellipses';
import { dataHooks } from '@wix/data-hooks';
import { st, classes } from './Dots.st.css';

export interface IDotsProps {
  popoverPlacement?: 'bottom-end' | 'bottom-start';
  onOpenMenu?(): void;
  menuItems: {
    title: string;
    callback(): void;
    isHidden?: boolean;
    className?: string;
  }[];
}

interface IDotsState {
  isShown: boolean;
}

export const dotsDataHooks = dataHooks<{
  main;
  element;
  content;
}>('dots');

export class Dots extends React.Component<IDotsProps, IDotsState> {
  static displayName = 'Dots';
  constructor(props: IDotsProps) {
    super(props);

    this.state = {
      isShown: false,
    };
  }

  onClick = () => {
    this.setState(
      (state) => ({
        isShown: !state.isShown,
      }),
      () => {
        if (this.state.isShown && this.props.onOpenMenu) {
          this.props.onOpenMenu();
        }
      },
    );
  };

  onKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Esc' || e.key === 'Escape') {
      this.setState({ isShown: false });
    }
  };

  onClickOutside = () => {
    this.setState({
      isShown: false,
    });
  };

  render() {
    return (
      <Popover
        className={classes.popover}
        dataHook={dotsDataHooks.main()}
        shown={this.state.isShown}
        dynamicWidth={true}
        maxWidth={250}
        onClickOutside={this.onClickOutside}
        placement={this.props.popoverPlacement || 'bottom-end'}
        showArrow={false}
      >
        <Popover.Element>
          <button
            data-hook={dotsDataHooks.element()}
            aria-label="Challenge actions"
            onClick={this.onClick}
            className={st(classes.root)}
          >
            <Ellipses />
          </button>
        </Popover.Element>
        <Popover.Content>
          <div onKeyDown={this.onKeyDown} data-hook={dotsDataHooks.content()}>
            <ActionsMenuLayout>
              {this.props.menuItems
                .filter((item) => !item.isHidden)
                .map((item, key) => {
                  return (
                    <ActionsMenuLayout.Item
                      key={key}
                      content={item.title}
                      onClick={() => {
                        this.setState(
                          {
                            isShown: false,
                          },
                          () => {
                            item.callback();
                          },
                        );
                      }}
                      className={item.className}
                    />
                  );
                })}
            </ActionsMenuLayout>
          </div>
        </Popover.Content>
      </Popover>
    );
  }
}
