import React from 'react';
import { useControllerData } from '../main/controllerContext';

export interface IMonitoringContext {
  startInteraction: Function;
  endInteraction?: Function;
}

export const monitoringDefaultValue = {
  startInteraction: () => {},
  endInteraction: () => {},
};

export function useMonitoring(): IMonitoringContext {
  const props = useControllerData();
  return {
    startInteraction: props.startInteraction,
    endInteraction: props.endInteraction,
  } as any;
}

export function useSingleEndInteraction(
  interactionName: string,
  entityId: string,
) {
  const { endInteraction } = useMonitoring();

  React.useEffect(() => {
    endInteraction(interactionName, entityId);
  }, [entityId]);

  return null;
}
