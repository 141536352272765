import React from 'react';
import { st, classes } from './RewardInfo.st.css';

import { Reward } from '@wix/ambassador-challenges-v1-challenge/types';
import { useTranslation } from '@wix/yoshi-flow-editor';
import { Badge as BadgeIcon } from '@wix/wix-ui-icons-common/on-stage';

import CertificateIcon from '../../assets/icons/certificate.svg';
import {
  getBadgesTrigger,
  getCertificateTrigger,
} from '../../selectors/rewards/rewardsSelectors';
import { Box } from '../Box';
import { getEarnedRewards } from '../../selectors/rewards/getEarnedRewards';
import { useUser } from '../../contexts/User/UserContext';
import { ALIGNMENT, AwardsBadges } from '../Badges';
import { ViewCertificate } from '../ViewCertificate';
import { FCWithChildren } from '@wix/challenges-web-library';

export interface IRewardInfoCompletedProps {
  rewards: Reward[];
  className?: string;
}

export const RewardInfoCompleted: FCWithChildren<IRewardInfoCompletedProps> = (
  props,
) => {
  const { participant } = useUser();
  const { rewards } = getEarnedRewards(props.rewards, participant);
  const { t } = useTranslation();

  const badgesTrigger = getBadgesTrigger(props.rewards);
  const certificateTrigger = getCertificateTrigger(props.rewards);

  if (!rewards.length && !participant.certificate) {
    return null;
  }

  return (
    <div className={st(classes.root, {}, props.className)}>
      {certificateTrigger && (
        <Box alignItems="flex-start" gap={3}>
          <span className={classes.icon}>
            <CertificateIcon />
          </span>
          <Box>{t('certificates.reward-info.trigger.complete-all-steps')}</Box>
          <Box>
            <ViewCertificate certificateId={participant.id} />
          </Box>
        </Box>
      )}
      {badgesTrigger && (
        <Box alignItems="flex-start" gap={3} marginTop={2}>
          <span className={classes.icon}>
            <BadgeIcon />
          </span>
          <span className={classes.text}>
            {t('live.challenges-page.modal.challenge-completed.reward')}
          </span>
          <AwardsBadges
            removeCustomBottomMargin={true}
            alignment={ALIGNMENT.left}
          />
        </Box>
      )}
    </div>
  );
};

RewardInfoCompleted.displayName = 'CompletedReward';
