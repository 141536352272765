import { SettingsKeysAbbreviation } from '../../../../../constants/settingsKeysAbbreviation';
import { useSettings } from '@wix/tpa-settings/react';
import { useCallback } from 'react';
import { ISettingsParam } from '@wix/tpa-settings';
import { abbreviationToSettingKey } from '../../../../../../settingsParams';

function isAbbreviation(
  key: SettingsKeysAbbreviation | any,
): key is SettingsKeysAbbreviation {
  return Object.values(SettingsKeysAbbreviation).includes(key);
}

export function useSettingsWithAbbreviation() {
  const settings = useSettings();
  const get = useCallback(
    function (key: SettingsKeysAbbreviation | ISettingsParam) {
      if (isAbbreviation(key)) {
        return settings.get(abbreviationToSettingKey(key));
      }
      return settings.get(key);
    },
    [settings.get],
  );

  const set = useCallback(
    function (key: SettingsKeysAbbreviation | ISettingsParam, value: any) {
      if (isAbbreviation(key)) {
        const convertedKey = abbreviationToSettingKey(key);
        return settings.set(convertedKey, value);
      }
      return settings.set(key, value);
    },
    [settings.set],
  );
  return {
    get,
    set,
  };
}
