import React from 'react';

import { FCWithChildren } from '@wix/challenges-web-library';
import { Box } from '../../../../../../components-shared/Box';
import {
  FocusPoint,
  useFocusManager,
} from '../../../../../../contexts/FocusManager';
import { SidebarLayoutButton } from '../../Buttons/SidebarLayoutButton/SidebarLayoutButton';
import { ButtonSize as ButtonSIZE } from 'wix-ui-tpa/cssVars';
import {
  ChevronLeftSmall,
  ChevronRightSmall,
} from '@wix/wix-ui-icons-common/on-stage';
import { useEnvironment, useExperiments } from '@wix/yoshi-flow-editor';
import { useSettings } from '@wix/tpa-settings/react';
import { useResolveStep } from '../../../../../../contexts/ResolveStep/ResolveStepContext';
import { sidebarLayoutDataHooks } from '../../../views/SidebarLayout/sidebarLayoutDataHooks';
import { ChallengeEntityType, IFollowingEntity } from '../../../views/utils';
import { Challenges } from '../../../../../../editor/types/Experiments';
import { useNavigationData } from './hooks';
import challengeSettings from '../../../../settingsParams';
import { classes, st } from './StepsNavigation.st.css';

export interface IStepNavigation {
  stepId: string;
  sectionId?: string;
  onChangeEntity(entity: IFollowingEntity): Promise<void>;
}

export const StepsNavigation: FCWithChildren<IStepNavigation> = (
  props: IStepNavigation,
) => {
  const { stepId, sectionId, onChangeEntity } = props;
  const { isMobile } = useEnvironment();
  const settings = useSettings();

  const { isResolveStepRequestInProgress } = useResolveStep();
  const { experiments } = useExperiments();
  const isUndoEnabled = experiments.enabled(Challenges.enableUndoButton);
  const { setNextFocusPoint } = useFocusManager();
  const { previousEntity, nextEntity, skipPrevious, skipNext } =
    useNavigationData(stepId, sectionId);

  if (!isUndoEnabled || (skipPrevious && skipNext)) {
    return null;
  }

  const PreviousButton =
    previousEntity.type !== ChallengeEntityType.VOID && !skipPrevious ? (
      <SidebarLayoutButton
        dataHook={sidebarLayoutDataHooks.stepPreviousBtn()}
        disabled={isResolveStepRequestInProgress}
        size={isMobile ? ButtonSIZE.small : ButtonSIZE.medium}
        prefixIcon={<ChevronLeftSmall />}
        moveFocus={false}
        isTextButton={true}
        onClick={async () => {
          setNextFocusPoint(FocusPoint.Top);
          await onChangeEntity(previousEntity);
        }}
      >
        {settings.get(challengeSettings.prevButtonTxt) as string}
      </SidebarLayoutButton>
    ) : (
      <div />
    );

  const NextButton =
    nextEntity.type !== ChallengeEntityType.VOID && !skipNext ? (
      <SidebarLayoutButton
        dataHook={sidebarLayoutDataHooks.stepNextBtn()}
        disabled={isResolveStepRequestInProgress}
        size={isMobile ? ButtonSIZE.small : ButtonSIZE.medium}
        suffixIcon={<ChevronRightSmall />}
        moveFocus={false}
        isTextButton={true}
        onClick={async () => {
          setNextFocusPoint(FocusPoint.Top);
          await onChangeEntity(nextEntity);
        }}
      >
        {settings.get(challengeSettings.nextButtonTxt) as string}
      </SidebarLayoutButton>
    ) : (
      <div />
    );

  return (
    <Box
      gap={6}
      direction="row"
      alignItems="center"
      className={st(classes.root, {
        mobile: isMobile,
        align: settings.get(challengeSettings.sidebarLayoutTextAlignment),
      })}
    >
      {PreviousButton}
      {NextButton}
    </Box>
  );
};
