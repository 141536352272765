import {
  Feedback,
  ParticipantStep,
  QuizSubmission,
  ResolutionStatus,
} from '@wix/ambassador-challenges-v1-participant/types';
import { IToastProviderProps } from '../ToastContext/interfaces';
import { useControllerData } from '../main/controllerContext';

export type ResolveStepParams = {
  stepId: string;
  feedback?: Feedback;
  status?: ResolutionStatus;
  errorMessage?: string;
  unresolvedMessage?: IToastProviderProps['toastMessage'];
  quizSubmission?: QuizSubmission;
  isUpdate?: boolean;
};

export interface IResolveStepContext {
  isResolveStepRequestInProgress: boolean;
  resolveStep: (params: ResolveStepParams) => Promise<void>;
  resolveStepData: Pick<
    ParticipantStep,
    'id' | 'quizSubmission' | 'transitions'
  >;
  resolveStepError: string;
}

export const useResolveStep = () => {
  const props = useControllerData();
  return {
    isResolveStepRequestInProgress: props.isResolveStepRequestInProgress,
    resolveStep: props.resolveStep,
    resolveStepData: props.resolveStepData,
    resolveStepError: props.resolveStepError,
  };
};
