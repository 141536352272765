import React, { useEffect, useRef } from 'react';
import ErrorIcon from '../../assets/icons/error.svg';
import { st, classes } from './Notification.st.css';
import { FCWithChildren } from '@wix/challenges-web-library';

interface INotificationProps {
  content: string;
  alignment?: any;
  withErrorIcon?: boolean;
  dataHook?: string;
  className?: string;
  maxWidth?: number;
}

export const Notification: FCWithChildren<INotificationProps> = (props) => {
  const { content, dataHook, withErrorIcon, alignment, className } = props;
  const divRef = useRef(null);
  useEffect(() => {
    if (divRef.current) {
      divRef.current.focus();
    }
  }, []);
  return (
    <div
      className={st(
        classes.root,
        {
          alignment,
        },
        className,
      )}
      data-hook={dataHook}
      ref={divRef}
      tabIndex={-1}
    >
      <div className={classes.inner}>
        {withErrorIcon ? <ErrorIcon className={classes.icon} /> : null}
        <span className={classes.content}>{content}</span>
      </div>
    </div>
  );
};
