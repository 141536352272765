import { isNeedToSelectStartDate } from '../../../../../../../../selectors/challenges';
import { isWithEligiblePPsAfterChallengeStartDate } from '../../../../../components/Pricing/helpers/getChallengeEligiblePaidPlans';
import {
  getChallengePaidPlans,
  isChallengeFree,
  IUserPaidPlan,
} from '@wix/challenges-web-library';
import { Challenge } from '@wix/ambassador-challenges-v1-challenge/types';
import { IUserType } from '../../../../../../../../contexts/User/UserContext';
import { JoinButtonIds } from './VisitorPageJoinButton';

/**
 For the flexible challenge with empty start date we need to set error state for the start date picker on join attempt.
 For the free challenge we need just to start join process both for the top and bottom buttons.
 For the paid challenge we need to scroll to bottom on click on the top button.
 */

export enum IJoinValidationAction {
  SCROLL_TO_FOOTER = 'SCROLL_TO_FOOTER',
  START_DATE_ERROR = 'START_DATE_ERROR',
  FUTURE_PLANS_ONLY_ERROR = 'FUTURE_PLANS_ONLY_ERROR',
}

export default ({
  buttonId,
  challenge,
  userType,
  startDate,
  userPaidPlans,
  eligiblePlans,
  isDisableEligiblePlans,
}: {
  buttonId: JoinButtonIds;
  challenge: Challenge;
  userType: IUserType;
  startDate: string;
  userPaidPlans: IUserPaidPlan[];
  eligiblePlans: any;
  isDisableEligiblePlans: boolean;
}): IJoinValidationAction[] => {
  const isTopJoinButton = buttonId === JoinButtonIds.Top;
  const challengePaidPlans = getChallengePaidPlans(
    challenge?.id,
    userPaidPlans || [],
  );
  const result = [];

  if (
    isNeedToSelectStartDate(challenge, userType, userPaidPlans) &&
    !startDate
  ) {
    if (isTopJoinButton) {
      result.push(IJoinValidationAction.SCROLL_TO_FOOTER);
    }
    result.push(IJoinValidationAction.START_DATE_ERROR);
  } else if (
    isTopJoinButton &&
    (isNeedToSelectStartDate(challenge, userType, userPaidPlans) ||
      !isChallengeFree(
        challenge?.settings?.pricing as any,
        challengePaidPlans.length,
      ))
  ) {
    result.push(IJoinValidationAction.SCROLL_TO_FOOTER);
  } else if (
    isWithEligiblePPsAfterChallengeStartDate(
      eligiblePlans,
      userPaidPlans,
      challenge,
    ) &&
    !isDisableEligiblePlans
  ) {
    if (isTopJoinButton) {
      result.push(IJoinValidationAction.SCROLL_TO_FOOTER);
    }
    result.push(IJoinValidationAction.FUTURE_PLANS_ONLY_ERROR);
  }

  return result;
};
