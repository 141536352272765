import { ConfirmDialog, IConfirmDialogProps } from '../ConfirmDialog';
import React from 'react';
import { useTranslation } from '@wix/yoshi-flow-editor';
import { leaveSessionModalDataHooks } from './leaveSessionModalDataHooks';
import { FCWithChildren } from '@wix/challenges-web-library';

type Props = Pick<
  IConfirmDialogProps,
  'opened' | 'onLeave' | 'onClose' | 'onCancel'
>;
export const LeaveSessionModal: FCWithChildren<Props> = ({
  opened,
  onLeave,
  onClose,
  onCancel,
}: Props) => {
  const { t } = useTranslation();

  return (
    <ConfirmDialog
      onLeave={onLeave}
      onCancel={onCancel}
      title={t('leave-the-session.title')}
      description={t('leave-the-session.description')}
      cancelLabel={t('leave-the-challenge.cancel')}
      confirmLabel={t('leave-the-challenge.cta')}
      opened={opened}
      onClose={onClose}
      dataHook={leaveSessionModalDataHooks.main()}
    />
  );
};
