import React from 'react';

import { MediaItem } from '@wix/ambassador-challenges-v1-participant/types';
import { useEnvironment } from '@wix/yoshi-flow-editor';
import { Video } from '../Video';
import { getMediaImagePreview } from '../../selectors/media';
import { FCWithChildren } from '@wix/challenges-web-library';
import { useIsFullWithInViewer } from '../../services/hooks/useIsFullWidthInViewer';
import { classes, st } from './MediaCover.st.css';
import { getMediaAspectRatio } from '../../utils/getMediaAspectRatio';

export interface IMediaCover {
  media: MediaItem;
  className?: string;
  maxHeight?: number;
  alignment?: 'left' | 'center' | 'right';
  fit?: 'contain' | 'cover'; // by default cover
  dataHook?: string;
  altText?: string;
}

export const MediaCover: FCWithChildren<IMediaCover> = (props) => {
  const { isMobile } = useEnvironment();
  const { fit = 'cover', dataHook = '' } = props;
  const isFullWidthLayout = useIsFullWithInViewer();
  if (!props.media) {
    return null;
  }

  return (
    <div
      className={st(
        classes.root,
        {
          alignment: props.alignment,
          mobile: isMobile,
          fit,
          fullWidth: isFullWidthLayout,
        },
        props.className,
      )}
    >
      <figure
        data-hook={dataHook}
        style={{
          width: '100%',
          paddingTop: `${
            100 / getMediaAspectRatio(props.media.image || props.media.video)
          }%`,
        }}
      >
        {props.media?.image ? (
          <img
            alt={props.altText}
            className={classes.mediaImage}
            src={getMediaImagePreview(props.media.image, 1000, 800)}
          />
        ) : (
          <Video
            altText={props.altText}
            className={classes.mediaImage}
            thumbnail={props.media?.video?.thumbnail}
            video={props.media?.video}
          />
        )}
      </figure>
    </div>
  );
};
