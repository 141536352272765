import { Participant, ParticipantV3, ParticipantV1 } from '../../types/v3Types';

export function isV3Participant(participant: Participant) {
  return (participant as ParticipantV3)?.revision !== undefined;
}
export function getState(participant: Participant) {
  if (isV3Participant(participant)) {
    return (participant as ParticipantV3).state;
  }
  return (participant as ParticipantV1)?.transitions?.[0]?.state;
}

export function getStartDate(participant: Participant) {
  if (isV3Participant(participant)) {
    const { joinedOptions, createdDate } = participant as ParticipantV3;
    return joinedOptions?.startDate || new Date(createdDate).toISOString();
  }
  return (participant as ParticipantV1).dateFrame?.start;
}
