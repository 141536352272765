import { FCWithChildren } from '@wix/challenges-web-library';
import { st, classes } from './HeaderLayout.st.css';
import React from 'react';
import { VisitorPageMedia } from '../VisitorPageMedia';
import { VisitorPageHeader } from './VisitorPageHeader';
import { useEnvironment } from '@wix/yoshi-flow-editor';

import { useGridLayout } from '../../../../../Settings/tabs/Layout/components/useGridLayout';

interface IHeaderLayoutProps {}

export const HeaderLayout: FCWithChildren<
  IHeaderLayoutProps
> = ({}: IHeaderLayoutProps) => {
  const { isMobile } = useEnvironment();
  const { isSideBySideLayout } = useGridLayout();

  return (
    <div className={st(classes.root)}>
      <VisitorPageHeader className={classes.header} />
      <VisitorPageMedia
        className={classes.media}
        isInline={isSideBySideLayout && !isMobile}
      />
    </div>
  );
};
