import React from 'react';
import { useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';
import { useSidebarLayoutBase } from '../../views/SidebarLayout/contexts/SidebarLayoutBase/SidebarLayoutBaseContext';
import utils from '../../views/utils';
import { classes } from './StepInTheFutureGuard.st.css';
import { FCWithChildren } from '@wix/challenges-web-library';
import { getFormattedDate } from '../StepsListAsTiles/GetFormattedDate';

export const StepInTheFutureGuard: FCWithChildren<{
  children: React.ReactNode;
}> = (props) => {
  const { t } = useTranslation();
  const { currentStep } = useSidebarLayoutBase();
  const { language } = useEnvironment();
  const isStepLocked = utils.isStepLocked(currentStep);

  return isStepLocked ? (
    <p className={classes.stepUnavailable}>
      {t('challenge.page.step-unavailable.warning', {
        date: getFormattedDate(language, currentStep.dateFrame.start, 'PP'),
      })}
    </p>
  ) : (
    <>{props.children}</>
  );
};
