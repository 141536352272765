import React from 'react';

export const Chevron = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="16"
      height="16"
      viewBox="0 0 16 16"
    >
      <defs>
        <path
          id="path-1"
          d="M5.99949921 0L0 5.2409396 0.657040314 6 5.99949921 1.33389262 11.3429597 6 12 5.2409396z"
        ></path>
      </defs>
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g transform="translate(2 5)">
          <mask fill="#fff">
            <use xlinkHref="#path-1"></use>
          </mask>
          <use
            fill="currentColor"
            transform="matrix(1 0 0 -1 0 6)"
            xlinkHref="#path-1"
          ></use>
        </g>
      </g>
    </svg>
  );
};
