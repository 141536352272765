import { useStyles } from '@wix/tpa-settings/react';
import challengeStyles from '../../../../stylesParams';
import { GridLayout } from '../../../challengeSettings/challengeSettings.types';

export const useGridLayout = () => {
  const styles = useStyles();

  return {
    get layout() {
      return styles.get(challengeStyles.visitorHeaderCols);
    },
    get isSideBySideLayout() {
      return this.layout === GridLayout.double;
    },
  };
};
