import React from 'react';

import { useEnvironment } from '@wix/yoshi-flow-editor';
import { FCWithChildren } from '@wix/challenges-web-library';

const TextWithDirection: FCWithChildren = (props: {
  children: React.ReactElement | 'string';
}) => {
  const { isRTL } = useEnvironment();
  const child: React.ReactElement =
    typeof props.children === 'string' ? (
      <span>{props.children}</span>
    ) : (
      props.children
    );

  return isRTL ? (
    React.cloneElement(child, { dir: isRTL ? 'rtl' : null })
  ) : (
    <>{props.children}</>
  );
};

export default TextWithDirection;
