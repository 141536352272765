import React from 'react';
import { ISettingsEvents } from '../../components/ChallengesPage/Widget/settingsEvents';
import { useControllerData } from '../main/controllerContext';

export interface ISettingsEventsContext extends ISettingsEvents {
  shownTab: ISettingsEvents['LayoutSelectedTab'];
}

export const useSettingsEvents = () => {
  const props = useControllerData();
  return {
    buttonState: props.buttonState,
    categoriesTabsState: props.categoriesTabsState,
    pageRole: props.pageRole,
    LayoutSelectedTab: props.shownTab || props.LayoutSelectedTab,
    shownTab: props.shownTab || props.LayoutSelectedTab,
    sidebarLayoutMobileScreen: props.sidebarLayoutMobileScreen,
  };
};

export const withSettingsEvents = <Props = any,>(
  Component: React.ComponentType<Props>,
) => {
  return (props: Props) => {
    const value = useSettingsEvents();

    return <Component {...props} {...value} />;
  };
};
