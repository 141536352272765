import React from 'react';
import { st, classes } from './PageSection.st.css';
import { useEnvironment } from '@wix/yoshi-flow-editor';
import { FCWithChildren } from '@wix/challenges-web-library';

export interface IPageSectionProps extends React.HTMLAttributes<HTMLElement> {
  title?: string;
  dataHook?: string;
  titleDataHook?: string;
  className?: string;
}

export const PageSection: FCWithChildren<IPageSectionProps> = (props) => {
  const { isMobile } = useEnvironment();

  return (
    <section
      className={st(classes.root, { mobile: isMobile }, props.className)}
      data-hook={props.dataHook}
      style={props.style}
    >
      {props.title ? (
        <h2
          className={classes.title}
          data-hook={props.titleDataHook}
          dir="auto"
        >
          {props.title}
        </h2>
      ) : null}
      <div className={classes.content} dir="auto">
        {props.children}
      </div>
    </section>
  );
};
