import { getSectionTitle } from './getSectionTitle';
import { useExperiments, useTranslation } from '@wix/yoshi-flow-editor';
import challengeSettings from '../../../../../../settingsParams';
import { SectionAbbreviation, sectionIds, VisitorSections } from './sectionIds';
import { SettingsKeysAbbreviation } from '../../../../../constants/settingsKeysAbbreviation';
import { useSettingsWithAbbreviation } from './useSettingsWithAbbreviation';
import { getOrderedItems } from '../../../../../../../../services/getOrderedItems';
import { useCallback } from 'react';

export const useGetColumnSectionSettingKey = (): ((sectionId: string) => {
  settingsKey: any;
}) => {
  const { experiments } = useExperiments();
  const enabledSettingsRedesign = experiments.enabled(
    'spec.programs.VisitorSettingsRedesign',
  );
  return useCallback(
    (sectionId: string) => {
      switch (sectionId) {
        default:
        case 'about':
          return {
            settingsKey: enabledSettingsRedesign
              ? SettingsKeysAbbreviation.visitorPageAboutShowInSidebar
              : null,
          };
        case 'overview':
          return {
            settingsKey: enabledSettingsRedesign
              ? SettingsKeysAbbreviation.visitorPageOverviewShowInSidebar
              : null,
          };
        case 'instructor':
          return {
            settingsKey:
              SettingsKeysAbbreviation.visitorPageInstructorShowInSidebar,
          };
        case 'start_date':
          return {
            settingsKey:
              SettingsKeysAbbreviation.visitorPageStartDayShowInSidebar,
          };
        case 'price':
          return {
            settingsKey: SettingsKeysAbbreviation.visitorPagePriceShowInSidebar,
          };
        case 'share':
          return {
            settingsKey: SettingsKeysAbbreviation.visitorPageShareShowInSidebar,
          };
        case 'group_discussion':
          return {
            settingsKey:
              SettingsKeysAbbreviation.visitorPageGroupDiscussionShowInSidebar,
          };
      }
    },
    [enabledSettingsRedesign],
  );
};
function useIsSectionInSidebar() {
  const getSettingsKeys = useGetColumnSectionSettingKey();
  const settings = useSettingsWithAbbreviation();
  return (sectionId: string) => {
    const { settingsKey } = getSettingsKeys(sectionId);
    if (settingsKey) {
      return settings.get(settingsKey);
    }
    return false;
  };
}

function convertSectionIdsToItems(sectionIds, t) {
  return sectionIds.map((item) => {
    return {
      id: item,
      label: getSectionTitle(item, t),
    };
  });
}

const getOrderedSections = (
  items: VisitorSections[],
  order: SectionAbbreviation[],
  hideStartDateEnabled: boolean,
) => {
  return getOrderedItems<VisitorSections, SectionAbbreviation>({
    order,
    items: items.filter((item) =>
      hideStartDateEnabled ? item !== 'start_date' : true,
    ),
    predicate(
      item: VisitorSections,
      orderedItem: SectionAbbreviation,
    ): boolean {
      const [orderSection] = Object.entries(SectionAbbreviation).find(
        ([_section, abbreviation]) => abbreviation === orderedItem,
      );
      return orderSection === item;
    },
  });
};

export const useSidebarItems = (hideStartDateEnabled?: boolean) => {
  const { t } = useTranslation();
  const settings = useSettingsWithAbbreviation();
  const isSectionInSidebar = useIsSectionInSidebar();
  const isSidebarEnabled = settings.get(
    challengeSettings.visitorPageBodyEnableSidebar,
  );
  const { order } = settings.get(
    SettingsKeysAbbreviation.visitorPageSidebarSectionsOrder,
  );

  const sections = isSidebarEnabled
    ? sectionIds.filter((id) => isSectionInSidebar(id))
    : [];

  return convertSectionIdsToItems(
    getOrderedSections(
      sections as VisitorSections[],
      order,
      hideStartDateEnabled,
    ),
    t,
  );
};

export const useBodyItems = (hideStartDateEnabled?: boolean) => {
  const { t } = useTranslation();
  const settings = useSettingsWithAbbreviation();
  const isSectionInSidebar = useIsSectionInSidebar();
  const isSidebarEnabled = settings.get(
    challengeSettings.visitorPageBodyEnableSidebar,
  );
  const { order } = settings.get(
    SettingsKeysAbbreviation.visitorPageBodySectionsOrder,
  );
  const sections = isSidebarEnabled
    ? sectionIds.filter((id) => !isSectionInSidebar(id))
    : sectionIds;

  return convertSectionIdsToItems(
    getOrderedSections(
      sections as VisitorSections[],
      order,
      hideStartDateEnabled,
    ),
    t,
  );
};
