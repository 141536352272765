export const getMediaAspectRatio = (image: {
  width?: number;
  height?: number;
}) => {
  if (
    typeof image?.width === 'number' &&
    typeof image?.height === 'number' &&
    image.height > 0 &&
    image.width > 0
  ) {
    return image.width / image.height;
  }
  return 16 / 9;
};
