import { Challenge } from '@wix/ambassador-challenges-v1-challenge/types';
import { getActiveParticipantsNumber } from './programs/getParticipantsNumber';

export function getParticipantsLimitInProgram(challenge: Challenge) {
  return challenge?.settings?.accessRestrictions?.maxParticipants;
}

export function isParticipantLimitReached(challenge: Challenge) {
  const participantsNumber = getActiveParticipantsNumber(challenge);
  const maxParticipants = getParticipantsLimitInProgram(challenge);
  return (
    participantsNumber &&
    maxParticipants &&
    !isNaN(participantsNumber) &&
    !isNaN(maxParticipants) &&
    participantsNumber >= maxParticipants
  );
}
