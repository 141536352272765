import React from 'react';
import { ConfirmDialog } from '../ConfirmDialog';
import { ButtonNames } from '../../contexts/main/biInterfaces';

import { useBi, useExperiments, useTranslation } from '@wix/yoshi-flow-editor';
import { memberWebAppButtonClick as memberWebAppButtonClickV2 } from '@wix/bi-logger-challenges-member-web/v2';

import { FedopsInteraction } from '../../config/fedopsInteraction';
import { useMonitoring } from '../../contexts/Monitoring/MonitoringContext';
import { leaveChallengeModalDataHooks } from './leaveChallengeModalDataHooks';
import { FCWithChildren } from '@wix/challenges-web-library';

export interface ILeaveChallengeModal {
  leaveProgram(participantId: string, programId: string): void;
  isOpen: boolean;
  participantId: string;
  challengeId?: string;
  onClose?(): void;
  onLeave?(): void;
  isPaidChallenge?: boolean;
}

export const LeaveChallengeModal: FCWithChildren<ILeaveChallengeModal> = (
  props,
) => {
  const [isOpen, setIsOpen] = React.useState<boolean>(props.isOpen);
  const bi = useBi();
  const { startInteraction } = useMonitoring();
  const { t } = useTranslation();
  const { experiments } = useExperiments();

  const beforeLeaveProgram = React.useCallback(async () => {
    await bi.report(
      memberWebAppButtonClickV2({
        buttonName: ButtonNames.LeaveTheChallengeAtModal,
      }),
    );
    await startInteraction(
      FedopsInteraction.LeaveFromProgram,
      props.challengeId,
    );
  }, []);

  const { onLeave = () => {}, onClose = () => {} } = props;
  const afterLeaveProgram = React.useCallback(() => {
    setIsOpen(false);
    onClose();
    onLeave();
  }, [onClose, onLeave]);

  const { leaveProgram, participantId, challengeId } = props;
  const onLeaveProgram = React.useCallback(async () => {
    await beforeLeaveProgram();
    leaveProgram(participantId, challengeId);
    afterLeaveProgram();
  }, [
    beforeLeaveProgram,
    leaveProgram,
    participantId,
    challengeId,
    afterLeaveProgram,
  ]);

  const onCloseLeaveModal = React.useCallback(async () => {
    setIsOpen(false);
    props.onClose();

    await bi.report(
      memberWebAppButtonClickV2({
        buttonName: ButtonNames.CancelLeaveTheChallengeModal,
      }),
    );
  }, []);

  React.useEffect(() => {
    setIsOpen(props.isOpen);
  }, [props.isOpen]);

  return (
    <ConfirmDialog
      onLeave={onLeaveProgram}
      onCancel={onCloseLeaveModal}
      title={t('leave-the-challenge.title')}
      description={
        props.isPaidChallenge &&
        experiments.enabled('specs.programs.MALeaveChallengeContent')
          ? t('leave-the-challenge.description.paid')
          : t('leave-the-challenge.description')
      }
      cancelLabel={t('leave-the-challenge.cancel')}
      confirmLabel={t('leave-the-challenge.cta')}
      opened={isOpen}
      onClose={onCloseLeaveModal}
      dataHook={leaveChallengeModalDataHooks.main()}
    />
  );
};
