import { dataHooks } from '@wix/data-hooks';

export const sidebarLayoutDataHooks = dataHooks<{
  main;
  spinner;
  sectionsListTiles;
  stepsListTiles;
  stepViewWrapper;
  stepView;
  stepUndo;
  stepCompleteBtn;
  stepPreviousBtn;
  stepNextBtn;
  quizSubmitBtn;
}>('sidebar-layout-for-participant');
