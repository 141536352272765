import React from 'react';

import { StepsListAsTiles } from '../StepsListAsTiles';

import { useSettings } from '@wix/tpa-settings/react';
import challengeSettings from '../../../settingsParams';
import { useTranslation } from '@wix/yoshi-flow-editor';

import { SectionsAsTilesAccordion } from '../../../../../components-shared/SectionsAsTilesAccordion';
import { isStepResolved } from '../../views/utils';
import { useSections } from '../../../../../contexts/ParticipantSections/ParticipantSectionsContext';
import {
  getSectionTitle,
  isSectionLocked,
} from '../../../../../selectors/sections';
import LockedIcon from '../../../../../assets/icons/locked-fill.svg';
import { useUser } from '../../../../../contexts/User/UserContext';
import { ParticipantStep } from '../../../../../types/v3Types';

import { classes } from './SectionsListAsTiles.st.css';

export interface ISectionsListAsTilesProps {
  dataHook?: string;
  dataHookForStepsList?: string;
  currentStepId: string;
  onSectionChosen?: Function;
  chosenSectionTileId?: string;
  onStepChosen(step: ParticipantStep): void;
}

export const SectionsListAsTiles: React.FunctionComponent<
  ISectionsListAsTilesProps
> = (props: ISectionsListAsTilesProps) => {
  const { onStepChosen, chosenSectionTileId } = props;
  const settings = useSettings();
  const { t } = useTranslation();
  const { isParticipantInSuspendedState, isParticipantInLockedState } =
    useUser();
  const {
    isListParticipantSectionsRequestInProgress,
    listParticipantSections = [],
  } = useSections();

  if (isListParticipantSectionsRequestInProgress) {
    return null;
  }

  return (
    <div data-hook={props.dataHook || null}>
      <ul className={classes.sectionsList}>
        {listParticipantSections.map((section, ind: number, arr) => {
          const steps = section.steps;
          const key = `section-${section.id}`;
          const sectionSteps = steps || [];
          const resolvedLength = sectionSteps.filter((step) =>
            isStepResolved(step),
          ).length;
          const isOpenSection = !!steps.find(
            (step) => step?.id === props.currentStepId,
          );

          const isActiveSection = chosenSectionTileId === section.id;
          const isLocked = isSectionLocked(section);

          const subtitle = (
            <>
              {isLocked ? <LockedIcon /> : null}
              {isParticipantInSuspendedState || isParticipantInLockedState
                ? t('live.challenges-page.section-steps-subtitle-short', {
                    total: sectionSteps.length,
                  })
                : t('live.challenges-page.section-steps-subtitle', {
                    resolved: resolvedLength,
                    total: sectionSteps.length,
                  })}
            </>
          );

          return (
            <li
              key={`section-${ind}`}
              data-hook={
                isLocked ? 'section-list-item-locked' : 'section-list-item'
              }
              className={classes.sectionsListItem}
            >
              <SectionsAsTilesAccordion
                id={key}
                key={key}
                lastItem={arr.length - 1 === ind}
                className={isActiveSection ? classes.sectionActive : ''}
                data-hook={
                  props.dataHook ? `${props.dataHook}__accordion-button` : null
                }
                opened={isOpenSection || false}
                title={getSectionTitle(section.source)}
                subtitle={subtitle}
                emptyChildren={!!steps.length}
                align={settings.get(
                  challengeSettings.sidebarLayoutTextAlignment,
                )}
                isEmpty={!steps?.length}
              >
                {!isLocked ? (
                  <StepsListAsTiles
                    dataHook={props.dataHookForStepsList}
                    isSection={true}
                    currentStepId={props.currentStepId}
                    steps={sectionSteps}
                    isSPC={true}
                    onStepChosen={onStepChosen}
                  />
                ) : null}
              </SectionsAsTilesAccordion>
            </li>
          );
        })}
      </ul>
    </div>
  );
};
SectionsListAsTiles.displayName = 'SectionsListAsTiles';
