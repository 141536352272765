import { FormSubmission } from '@wix/ambassador-forms-v4-submission/types';
import { QuizSubmission } from '@wix/ambassador-achievements-quizzes-v1-quiz-submission/types';
import { useControllerData } from '../main/controllerContext';

export interface IQuizContext {
  controllerIsReady: boolean; // to determine the moment when the controller is ready and form data loaded
  initController: Function; // init forms controller
  sendSubmission: Function; // send the submission (to oprog service)
  sendSubmissionInProgress: boolean; // send submission request is in progress
  lastSubmissionId?: string; // save the last id to pass it to oprog resolve step and also its using as a flag that submission was sent
  getSubmission: Function; // get submission data
  getSubmissionInProgress: boolean; // get submission request is in progress
  submission?: Pick<FormSubmission, 'submissions'> & {
    // submission data
    answers: QuizSubmission['answers'];
  };
  earnedGrade: number; // completed score (shared between form component and controls)
  resubmitTheQuiz: Function; // clear the submission and all related data from the store
  clearQuizStore: Function; // clear on unmount
}

export const quizDefaultValue = {
  controllerIsReady: false,
  initController: () => {},
  sendSubmission: () => {},
  sendSubmissionInProgress: false,
  lastSubmissionId: null,
  getSubmission: () => {},
  getSubmissionInProgress: false,
  submission: null,
  earnedGrade: null,
  resubmitTheQuiz: () => {},
  clearQuizStore: () => {},
};

export function useQuiz(): IQuizContext {
  const props = useControllerData();
  return {
    controllerIsReady: !!props.controllerIsReady,
    initController: props.initController || quizDefaultValue.initController,
    sendSubmission: props.sendSubmission || quizDefaultValue.sendSubmission,
    sendSubmissionInProgress: !!props.sendSubmissionInProgress,
    lastSubmissionId:
      props.lastSubmissionId || quizDefaultValue.lastSubmissionId,
    getSubmission: props.getSubmission || quizDefaultValue.getSubmission,
    getSubmissionInProgress: !!props.getSubmissionInProgress,
    submission: props.submission || quizDefaultValue.submission,
    earnedGrade: props.earnedGrade ?? quizDefaultValue.earnedGrade,
    resubmitTheQuiz: props.resubmitTheQuiz || quizDefaultValue.resubmitTheQuiz,
    clearQuizStore: props.clearQuizStore || quizDefaultValue.clearQuizStore,
  };
}
