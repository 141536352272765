import React from 'react';
import { Text, TextProps } from 'wix-ui-tpa/cssVars';
import { st, classes } from './DescriptionText.st.css';

export type IDescriptionTextProps = {
  children: React.ReactNode;
  pageType: 'visitor' | 'participant';
} & TextProps;

export const DescriptionText: React.FC<IDescriptionTextProps> = (props) => {
  const { pageType, className, children } = props;

  return (
    <Text {...props} className={st(classes.root, { pageType }, className)}>
      {children}
    </Text>
  );
};
