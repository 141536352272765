import React from 'react';

import { FCWithChildren } from '@wix/challenges-web-library';
import { Modal } from '../../../../../components-shared/Modal';
import { ProgramUnavailable } from '../ProgramUnavailable';
import { useVisitorPageBase } from '../../views/VisitorPage/contexts/VisitorPageBase/VisitorPageBaseContext';
import { useLoading } from '../../../../../contexts/Loading/LoadingContext';
import { ILoadingStatus } from '../../../../../contexts/Loading/interfaces';
import { Challenges } from '../../../../../editor/types/Experiments';
import { useExperiments } from '@wix/yoshi-flow-editor';

export interface IProgramUnavailableModalProps {
  dataHook?: string;
  onClose?(): void;
}

export const ProgramUnavailableModal: FCWithChildren<
  IProgramUnavailableModalProps
> = ({ dataHook, onClose }: IProgramUnavailableModalProps) => {
  const { isPremiumOutdatedModalOpened, setIsPremiumOutdatedModalOpened } =
    useVisitorPageBase();
  const { isLoading, loadingStatus } = useLoading();
  const { experiments } = useExperiments();
  const isPremiumOOIEnabled = experiments.enabled(Challenges.enablePremium);

  React.useEffect(() => {
    if (
      !isLoading &&
      loadingStatus === ILoadingStatus.NOT_ELIGIBLE &&
      isPremiumOOIEnabled
    ) {
      setIsPremiumOutdatedModalOpened(true);
    }
  }, [isLoading]);

  return (
    <Modal
      opened={isPremiumOutdatedModalOpened}
      onClose={() => {
        setIsPremiumOutdatedModalOpened(false);
        onClose?.();
      }}
      dataHook={dataHook}
      key={dataHook}
    >
      <ProgramUnavailable type="JoinModal" />
    </Modal>
  );
};
