import { IChallengeContext } from '../contexts/storage-contexts/Challenge';
import { isSpecific } from './isSpecific';
import { isOngoing } from './challenges';
import { getRightDateFromBackendDateTime } from './dates';

export const getStartDate = (
  challenge: IChallengeContext['challengeData']['challenge'],
) => {
  const startDate =
    (isSpecific(challenge) &&
      challenge?.settings?.timelineType?.specific?.startTime) ||
    (isOngoing(challenge) &&
      challenge?.settings?.timelineType?.ongoing?.startTime);
  return startDate ? getRightDateFromBackendDateTime(startDate) : null;
};
