import React from 'react';

export function useScrollToTopOnMount(ref: React.RefObject<HTMLElement>) {
  React.useEffect(() => {
    const element = ref.current;
    if (element) {
      element.scrollTo?.({ top: 0 });
    }
  }, [ref]);
}
