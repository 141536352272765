import { getRightDateFromBackend } from '../../../../../selectors/dates';

type InlFormatDateOption = 'PP' | 'dd/MM/yyyy';
export const getInlFormatDateOption = (
  format?: InlFormatDateOption,
): Intl.DateTimeFormatOptions => {
  if (format === 'PP') {
    return {
      year: 'numeric', // Full year (e.g., "2024")
      month: 'short', // Abbreviated month (e.g., "Jul")
      day: 'numeric',
    };
  }
  if (format === 'dd/MM/yyyy') {
    return {
      year: 'numeric', // Full year (e.g., "2024")
      month: '2-digit', // Abbreviated month (e.g., "Jul")
      day: '2-digit',
    };
  }
  return {
    weekday: 'short', // Full name of the day (e.g., "Thursday")
    year: 'numeric', // Full year (e.g., "2024")
    month: 'short', // Abbreviated month (e.g., "Jul")
    day: 'numeric', // Numeric day (e.g., "25")
  };
};

export function getFormattedDate(
  language: string,
  startDate: string | Date,
  dateFormat?: InlFormatDateOption,
) {
  const dateTimeFormat = new Intl.DateTimeFormat(
    language,
    getInlFormatDateOption(dateFormat),
  );

  if (typeof startDate === 'string') {
    return dateTimeFormat.format(getRightDateFromBackend(startDate));
  }
  return dateTimeFormat.format(startDate);
}
