import { Participant, ParticipantV1, ParticipantV3 } from '../../types/v3Types';

function isV3Participant(participant: Participant) {
  return (participant as ParticipantV3)?.revision !== undefined;
}

export const getProgress = (participant: Participant) => {
  if (!participant) {
    return 0;
  }
  if (isV3Participant(participant)) {
    const { progress } = participant as ParticipantV3;
    return progress?.completionPercentage || 0;
  }
  const { stepsSummary } = participant as ParticipantV1;
  return stepsSummary?.completedStepsNumber
    ? (stepsSummary.completedStepsNumber / stepsSummary.stepsNumber) * 100
    : 0;
};
