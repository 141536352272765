import React, { useEffect, useMemo } from 'react';
import {
  useBi,
  useEnvironment,
  usePanorama,
  useTranslation,
} from '@wix/yoshi-flow-editor';
import { useSettings } from '@wix/tpa-settings/react';
import loadable from '@wix/yoshi-flow-editor/loadable';
import { FormError, FormValues } from '@wix/form-viewer/widget';
import { FormHandle } from '@wix/form-viewer';
import { useQuiz } from '../../../../../contexts/Quiz/QuizContext';
import { useResolveStep } from '../../../../../contexts/ResolveStep/ResolveStepContext';
import {
  Spinner,
  SpinnerTypes,
  Text,
  TextTypography,
} from 'wix-ui-tpa/cssVars';
import { formQuizDataHooks } from './formQuizDataHooks';
import { useSidebarLayoutBase } from '../../views/SidebarLayout/contexts/SidebarLayoutBase/SidebarLayoutBaseContext';
import { StepViewHidden } from '../StepViewHidden';
import TextWithDirection from '../../../../../components-shared/TextWithDirection';
import { Fullscreen } from '../Fullscreen/Fullscreen';
import { FCWithChildren } from '@wix/challenges-web-library';
import { FormQuizViewerProps } from './FormViewerQuizTypes';

import { classes, st } from './FormQuiz.st.css';
import utils from '../../views/utils';

// Type for the ref object, that will be used to share the imperative methods of form viewer.
export interface IFormHandleExtended extends FormHandle {
  submitTheForm: () => void;
  dirty: boolean;
}

export interface IFormQuiz {
  formId: string;
  submissionId?: string;
  onResolve?: Function;
  stepName?: string;
  ref: React.ForwardedRef<IFormHandleExtended>;
  Breadcrumbs: () => React.ReactElement;
  children?: React.ReactNode;
}

const FormViewerQuiz: FCWithChildren<FormQuizViewerProps> = loadable(() => {
  return import(/* webpackPrefetch: true */ './FormViewerQuiz');
});

export const FormQuiz: FCWithChildren<IFormQuiz> =
  React.forwardRef<IFormHandleExtended>(
    (
      { formId, submissionId, onResolve, stepName, Breadcrumbs }: IFormQuiz,
      formViewerRef: React.ForwardedRef<IFormHandleExtended>,
    ) => {
      const bi = useBi();
      const panorama = usePanorama();
      const { t, i18n } = useTranslation();
      const { isMobile, isRTL } = useEnvironment();
      const settings = useSettings();
      const alignment = utils.getContentAlignByType(settings, isRTL);
      const {
        controllerIsReady,
        initController,
        sendSubmission,
        sendSubmissionInProgress,
        lastSubmissionId,
        getSubmission,
        getSubmissionInProgress,
        submission,
        earnedGrade,
        clearQuizStore,
        resubmitTheQuiz,
      } = useQuiz();
      const { isResolveStepRequestInProgress } = useResolveStep();
      const { isCurrentStepHidden, currentStep, goToCurrentStep, pageRef } =
        useSidebarLayoutBase();

      const [isMounted, setIsMounted] = React.useState<boolean>(false);

      const [values, setValues] = React.useState<FormValues>({});
      const [errors, setErrors] = React.useState<FormError[]>([]);
      const [_dirty, setDirty] = React.useState(false);

      const valuesAsDependency = values ? Object.values(values)?.join('.') : '';
      const errorsAsDependency = errors?.length || 0;

      const isFilled = !!submission;
      const passingGrade = utils.getPassingGrade(currentStep, isFilled);
      const hasPassingGrade = !!passingGrade;
      // const isQuizPassed = !hasPassingGrade || earnedGrade >= passingGrade;

      const answersAsDependency = submission?.answers
        ? Object.values(submission?.answers)
            .map((answer) => answer?.value)
            .join('.')
        : '';

      const stepQuizSubmissionId =
        currentStep?.quizSubmission?.quizSubmissionId;

      const externalData = useMemo(() => {
        /**
         * Initially submission is undefined, FromViewer shows error in that case.
         * "useExternalData should be used within ExternalDataProvider".
         * passing empty answer resolving the issue.
         */
        return { answers: submission?.answers || {} };
      }, [answersAsDependency]);

      const submitTheForm = React.useCallback(() => {
        if (
          !errors?.length &&
          Object.keys(values).length &&
          !sendSubmissionInProgress &&
          !isResolveStepRequestInProgress
        ) {
          setDirty(false);
          sendSubmission(formId, values);
        }
      }, [
        formId,
        valuesAsDependency,
        errorsAsDependency,
        sendSubmissionInProgress,
        isResolveStepRequestInProgress,
      ]);

      const changeValueSafely = React.useCallback(
        (type: 'values' | 'errors', newValue) => {
          const currentValue = type === 'values' ? values : errors;
          const changeValueMethod = type === 'values' ? setValues : setErrors;
          const shouldChangeValue =
            !isFilled &&
            JSON.stringify(currentValue) !== JSON.stringify(newValue);

          if (shouldChangeValue) {
            const newValue1 =
              type === 'values' ? { ...currentValue, ...newValue } : newValue;

            changeValueMethod(newValue1);

            if (
              type === 'values' &&
              Object.values(newValue).join('') &&
              !_dirty
            ) {
              setDirty(true);
            }
          }
        },
        [valuesAsDependency, errorsAsDependency, submission],
      );

      // Extend the imperative methods of FormViewer to have an ability to call `submit` outside the component.
      const formViewerRefObject =
        formViewerRef as React.RefObject<IFormHandleExtended>;
      React.useImperativeHandle(
        formViewerRef,
        () =>
          ({
            validate: formViewerRefObject?.current?.validate,
            validateStep: (formViewerRefObject?.current as any)?.validateStep,
            submitTheForm,
            get dirty() {
              return _dirty;
            },
          } as IFormHandleExtended),
      );

      React.useEffect(() => {
        // The dirty fix that adds the confidence that previous instance of form was properly cleaned.
        // , thats because of sharing the same state between instances of the form.
        setTimeout(() => {
          setIsMounted(true);
        }, 200);

        // initialization in the forms side
        // passing grade came from step data, so we should also set it on initialization
        if (!isCurrentStepHidden) {
          void initController(formId);
        }

        // if the quiz already has the submission, then load it
        if (submissionId) {
          void getSubmission(submissionId);
        }

        return () => {
          clearQuizStore();
        };
      }, []);

      useEffect(() => {
        if (!stepQuizSubmissionId) {
          resubmitTheQuiz();
        }
      }, [stepQuizSubmissionId]);

      const submissionsAsDependency = submission?.submissions
        ? Object.values(submission?.submissions).join('.')
        : '';
      React.useEffect(() => {
        // set the already existed and requested submission
        if (submission) {
          setValues(submission?.submissions);
        } else {
          // retake the quiz: clear the values
          setValues({});
        }

        setErrors([]);
      }, [submissionsAsDependency]);

      React.useEffect(() => {
        if (lastSubmissionId && values && Object.keys(values).length) {
          void onResolve?.(lastSubmissionId);
        }
      }, [lastSubmissionId]);

      if (isCurrentStepHidden) {
        return (
          <StepViewHidden
            date={currentStep?.dateFrame?.start}
            goToCurrentStep={goToCurrentStep}
          />
        );
      }

      return (
        <section
          className={st(classes.root, {
            mobile: isMobile,
            filled: isFilled,
            alignment,
          })}
          data-hook={formQuizDataHooks.main()}
        >
          <Breadcrumbs />
          <Fullscreen pageRef={pageRef} />

          <TextWithDirection>
            <h3 className={classes.title} data-hook={formQuizDataHooks.title()}>
              {stepName}
            </h3>
          </TextWithDirection>

          {controllerIsReady &&
          isMounted &&
          isFilled &&
          hasPassingGrade &&
          !getSubmissionInProgress &&
          !isResolveStepRequestInProgress ? (
            <section className={classes.stats}>
              <Text
                typography={TextTypography.runningText}
                className={classes.statsTitle}
              >
                {t('live-site.step-quiz.earned-grade-msg', {
                  earnedGrade,
                })}
              </Text>
              {earnedGrade < passingGrade ? (
                <Text
                  typography={TextTypography.runningText}
                  className={classes.statsDescription}
                >
                  {t('live-site.step-quiz.passing-grade-msg', {
                    passingGrade,
                  })}
                </Text>
              ) : null}
            </section>
          ) : null}

          {controllerIsReady && isMounted && !getSubmissionInProgress ? (
            <div
              data-hook={formQuizDataHooks.inner()}
              className={classes.inner}
              dir={isRTL ? 'rtl' : undefined}
            >
              <FormViewerQuiz
                i18n={i18n}
                bi={bi}
                formViewerRef={formViewerRef}
                formId={formId}
                onChange={(update) => {
                  changeValueSafely('values', update);
                }}
                onValidate={(update) => {
                  changeValueSafely('errors', update);
                }}
                values={values}
                errors={errors}
                externalData={externalData}
                panorama={panorama}
              />
            </div>
          ) : (
            <Spinner
              type={SpinnerTypes.slim}
              isCentered={!isMobile}
              className={classes.spinner}
            />
          )}
        </section>
      );
    },
  );
